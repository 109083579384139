import { visibleRatingsChanged } from '../action'
import { selectVisibleRatings } from '../selector/rating-global'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


class RatingCheckbox extends Component {
	render() {
		return (
			<div className="RatingCheckbox">
				<div className="btn-toolbar" role="toolbar">
					<div className="btn-group" role="group" data-toggle="buttons-checkbox">
						<button
							type="button"
							style={{width: '10em'}}
							className={"btn btn-primary "+(this.props.visibleRatings.size === 4 ? 'active' : '')}
							onClick={() => this.props.onChange(null)}
						>{this.props.visibleRatings.size === 4 ? 'Скрыть все' : 'Показать все'}</button>
					</div>
					<div className="btn-group" role="group" data-toggle="buttons-checkbox">
						<button
							type="button"
							style={{width: '10em'}}
							className={"btn btn-primary "+(this.props.visibleRatings.indexOf('area') !== -1 ? 'active' : '')}
							onClick={() => this.props.onChange('area')}
						>По магазинам</button>
						<button
							type="button"
							style={{width: '10em'}}
							className={"btn btn-primary "+(this.props.visibleRatings.indexOf('executor') !== -1 ? 'active' : '')}
							onClick={() => this.props.onChange('executor')}
						>По подрядчикам</button>
						<button
							type="button"
							style={{width: '10em'}}
							onClick={() => this.props.onChange('worker')}
							className={"btn btn-primary "+(this.props.visibleRatings.indexOf('worker') !== -1 ? 'active' : '')}
						>По мастерам</button>
						<button
							type="button"
							style={{width: '10em'}}
							className={"btn btn-primary "+(this.props.visibleRatings.indexOf('what') !== -1 ? 'active' : '')}
							onClick={() => this.props.onChange('what')}
						>&nbsp;&nbsp;По типу&nbsp;&nbsp;</button>
					</div>
				</div>
			</div>
		)
	}
}

RatingCheckbox.propTypes = {
	visibleRatings: PropTypes.any.isRequired,
	onChange: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
	return {
		visibleRatings: selectVisibleRatings(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (value) => {
			dispatch(visibleRatingsChanged(value))
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RatingCheckbox)
