import { withPath } from '../../selector-util'
import * as source from './common-local'

const PATH = ['install']
let unwrap = withPath(PATH)

export const selectKnownShops = unwrap(source.selectKnownShops)
export const selectKnownWorkers = unwrap(source.selectKnownWorkers)
export const selectKnownExecutors = unwrap(source.selectKnownExecutors)
export const selectKnownTypes = unwrap(source.selectKnownTypes)
export const selectDataRange = unwrap(source.selectDataRange)
