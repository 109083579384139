import { createAction } from 'redux-actions'


export const testKeyInc = createAction('INSTALL_TEST_KEY_INC', () => ({ /* O_O */ }))

export const filterRegionChanged = createAction('INSTALL_FILTER_REGION_CHANGED', (value) => ({ value }))
export const filterCityChanged = createAction('INSTALL_FILTER_CITY_CHANGED', (value) => ({ value }))
export const filterAreaChanged = createAction('INSTALL_FILTER_AREA_CHANGED', (value) => ({ value }))
export const filterWorkerChanged = createAction('INSTALL_FILTER_WORKER_CHANGED', (value) => ({ value }))
export const filterExecutorChanged = createAction('INSTALL_FILTER_EXECUTOR_CHANGED', (value) => ({ value }))
export const filterTypeChanged = createAction('INSTALL_FILTER_TYPE_CHANGED', (value) => ({ value }))

export const filterDateFirstChanged = createAction('INSTALL_FILTER_DATE_FIRST_CHANGED', (year, month, day) => ({ year, month, day }))
export const filterDateLastChanged = createAction('INSTALL_FILTER_DATE_LAST_CHANGED', (year, month, day) => ({ year, month, day }))
export const filterDateJump = createAction('INSTALL_FILTER_DATE_JUMP', (shortcut) => ({ shortcut }))

export const generalModeChanged = createAction('INSTALL_GENERAL_MODE_CHANGED', (value) => ({ value }))
export const commentKeyChanged = createAction('INSTALL_COMMENT_KEY_CHANGED', (value) => ({ value }))
export const ratingCriteriaChanged = createAction('INSTALL_RATING_CRITERIA_CHANGED', (value) => ({ value }))
export const visibleRatingsChanged = createAction('INSTALL_RATING_LIST_CHANGED', (value) => ({ value }))

export const comparisonColumnAddCurrent = createAction('INSTALL_COMPARISON_COLUMN_ADD_CURRENT', () => ({ /* O_O */ }))
export const comparisonColumnDelete = createAction('INSTALL_COMPARISON_COLUMN_DELETE', (key) => ({ key }))
export const comparisonDataLoaded = createAction('INSTALL_COMPARISON_DATA_LOADED', () => ({ /* O_O */ }))
export const comparisonCriteriaChanged = createAction('INSTALL_COMPARISON_CRITERIA_CHANGED', (value) => ({ value }))
