import React, { Component } from 'react'
import { connect } from 'react-redux'

import { selectSummaryDataPretty } from '../selector/summary'
import HorizontalBarChartCJ from '../../generic/HorizontalBarChartCJ'
import { INSTALL_TARGET_CSI, INSTALL_TARGET_CSI_WORKER, INSTALL_TARGET_NPS } from '../../config'
import { roundPolicy } from '../../selector-util'


class Summary extends Component {
	render() {
		let data = this.props.summaryData
		let csiGap = roundPolicy(data.csi - INSTALL_TARGET_CSI)
		let csiWorkerGap = roundPolicy(data.csi_worker - INSTALL_TARGET_CSI_WORKER)
		let npsGap = roundPolicy(data.nps - INSTALL_TARGET_NPS)

		if (data.count > 0) {
			return (
				<div className="summary">
					<h3>Общие результаты</h3>
					<div className="text-left text-muted"><small>Отклонения от целевых показателей даны справа от результата по параметру</small></div>
					<div className="col-sm-3" style={{ paddingLeft: 0, textAlign: 'center' }}>
						<h4 style={{ marginTop: 15, marginBottom: 0  }}>CSI (в целом)</h4>
						<h1 style={{ color: '#41BB96', marginTop: 15, marginBottom: 0 }}>{data.csi}% <font style={{ color: (csiGap < 0) ? '#FA7F9D' : '#86D4BC', fontSize: '20px' }}>{(csiGap < 0) ? '' : '+'}{csiGap}%</font></h1>
						<div className="sorted-stacked-chart-csi" style={{ paddingLeft: '20px' }}>
							<HorizontalBarChartCJ
								dataObject={{csi: [data.satisfied, data.dissatisfied]}}
								dataColor={['#41BB96', '#EB5454']}
								dataPercent={true}
								dataStacked={true}
								decimalPlaces={0}
								turnXAxisOff={true}
								turnYAxisOff={true}
								turnTooltipsOff={true}
								height={100}
						/>
						</div>
					</div>
					<div className="col-sm-3" style={{ paddingLeft: 0, textAlign: 'center' }}>
						<h4 style={{ marginTop: 15, marginBottom: 0  }}>CSI (по мастерам)</h4>
						<h1 style={{ color: '#41BB96', marginTop: 15, marginBottom: 0 }}>{data.csi_worker}% <font style={{ color: (csiWorkerGap < 0) ? '#FA7F9D' : '#86D4BC', fontSize: '20px' }}>{(csiWorkerGap < 0) ? '' : '+'}{csiWorkerGap}%</font></h1>
						<div className="sorted-stacked-chart-csi-worker" style={{ paddingLeft: '20px' }}>
							<HorizontalBarChartCJ
								dataObject={{csi: [data.worker_satisfied, data.worker_dissatisfied]}}
								dataColor={['#41BB96', '#EB5454']}
								dataPercent={true}
								dataStacked={true}
								decimalPlaces={0}
								turnXAxisOff={true}
								turnYAxisOff={true}
								turnTooltipsOff={true}
								height={100}
						/>
						</div>
					</div>
					<div className="col-sm-3" style={{ paddingLeft: 0, textAlign: 'center'}}>
						<h4 style={{ marginTop: 15, marginBottom: 0  }}>NPS</h4>
						<h1 style={{ color: '#41BB96', marginTop: 15, marginBottom: 0 }}>{data.nps}% <font style={{ color: (npsGap < 0) ? '#FA7F9D' : '#86D4BC', fontSize: '20px' }}>{(npsGap < 0) ? '' : '+'}{npsGap}%</font></h1>
						<div className="sorted-stacked-chart-nps" style={{ paddingLeft: '20px' }}>
							<HorizontalBarChartCJ
								dataObject={{nps: [data.promoter, data.neutral, data.detractor ]}}
								dataColor={['#41BB96', '#A6A6A6', '#EB5454']}
								dataPercent={true}
								dataStacked={true}
								decimalPlaces={0}
								turnXAxisOff={true}
								turnYAxisOff={true}
								turnTooltipsOff={true}
								height={100}
						/>
						</div>
					</div>
					<div className="col-sm-3" style={{ paddingLeft: 0, textAlign: 'center'}}>
						<h4 style={{ marginTop: 15, marginBottom: 0  }}>Собрано отзывов</h4>
						<h1 style={{ color: '#A6A6A6', marginTop: 35, marginBottom: 0 }}>{data.count}</h1>
					</div>
					<div className="col-sm-12"><hr /></div>
				</div>
			)
		} else {
			return (
				<div className="summary"></div>
			)
		}
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		summaryData: selectSummaryDataPretty(state).toJS()
	}
}

export default connect(
	mapStateToProps,
)(Summary)
