import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './TableOverObject.module.css'
import { traverseToTarget } from './jsx.js'
import SmartassList from './SmartassList.js'

const roundPolicy = (x) => Math.round(x * 10) / 10

const toPercent = (x) => roundPolicy(x * 100)

export default class TableOverObject extends Component {
	constructor() {
		super()
		this.handleRowClicked = this.handleRowClicked.bind(this)
	}

	handleRowClicked(event) {
		if (!this.props.onRowClicked) return

		let target = traverseToTarget(event.target, 'tr')
		let key = target.attributes['data-key'].value
		this.props.onRowClicked(key, event)
	}

	render() {
		let ret = this.props.data
		let maxKey = this.props.maxKey
		let maxValue = this.props.maxValue

		let tableClass = this.props.tableClass || ""
		let rowClass = this.props.rowClass || (() => '')

		let onRowClicked = this.props.onRowClicked

		return (
			<div className="TableOverObject">
				<table className={tableClass}>
					<tbody>
						<SmartassList>
							{this.props.definition.map((crit, index) => {
								let curr = ret[crit.key]
								let max = maxValue
								if (maxKey) {
									max = ret[maxKey]
								}

								let val = curr / max
								let valp = curr * 100 / max
								let className = "progress-bar-success"
								if (valp < 0) {
									valp = Math.abs(valp)
									className = "progress-bar-danger"
								}

								// if (max === 0) {
								// 	val = 0
								// 	valp = 0
								// }

								return (
									<tr
										key={index}
										data-key={crit.key}
										className={(!!onRowClicked ? "clickable" : "") + ' ' + rowClass(crit, curr)}
										onClick={this.handleRowClicked}
									>
										<td className={style.titleCell}>{crit.title}</td>
										<td className={style.percentCell}><big>{toPercent(val)}%</big></td>
										<td className={style.barCell}>
											<div className="progress">
												<div
													className={`progress-bar ${className}`}
													role="progressbar"
													aria-valuenow={Math.round(valp)}
													aria-valuemin="0"
													aria-valuemax="100"
													style={{ width: `${Math.round(valp)}%` }}
												/>
											</div>
										</td>
									</tr>
								)
							})}
						</SmartassList>
					</tbody>
				</table>
			</div>
		)
	}
}

TableOverObject.propTypes = {
	definition: PropTypes.array.isRequired,
	data: PropTypes.object.isRequired,
	maxValue: PropTypes.number,
	maxKey: PropTypes.string,
	tableClass: PropTypes.string,
	onRowClicked: PropTypes.func,
}
