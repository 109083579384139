import React, { Component } from 'react'
import { connect } from 'react-redux'
import SortedKeysChartCJ from '../../generic/SortedKeysChartCJ'
import ComparisonCriteriaSwitch from './ComparisonCriteriaSwitch'

import './ComparisonChart.css'

const DATA_COLORS = [
	'#c0e0d3',
	'#9dd2bf',
	'#6bc3a5',
	'#3eb28f',
	'#369f7f',
	'#2d896d',
]

class ComparisonChart extends Component {
	render() {
		let keyTitles = this.props.keytitles
		let months = this.props.months
		let ret = []
		if (keyTitles.length > 1) {
			ret.push(
				<div key='headerComparisonDynamics' className="col-md-12">
					<div className="pull-right">
						<ComparisonCriteriaSwitch />
					</div>
					<h4>Сравнение в динамике</h4>
				</div>
			)

			let tableContent = []
			keyTitles.forEach(title => {
				let data = this.props.data[title]
				tableContent.push(
					<td style={{padding: '1.0em', overflow: 'hidden'}} key={title}>
						<div className="ComparisonChart">
							<SortedKeysChartCJ
								turnXAxisLabelsOff={true}
								turnYAxisOff={true}
								chartTitle={title}
								dataObject={data}
								// canvasWidth={100}
								dataLabel={' '}
								// массив в массиве для правильной передачи цветов на гистограмме
								// элементы массива первого уровня соответствуют рядам данных гистограммы
								// фактически тут массив цветов передается первому (и в данном случае единственному) ряду гистограммы
								dataColor={[DATA_COLORS.slice(-months.length)]}
								dataPercent={true}
								bar={{
									width: {
										ratio: 0.75, // this makes bar width N% of length between ticks
									},
								}}
								legend={{
									hide: true,
								}}
							/>
						</div>
					</td>
				)
			})

			ret.push(
				<table className="table" key='chartsContainer' style={{width: '100%', tableLayout:'fixed'}}>
					<tr>
						{tableContent}
					</tr>
				</table>
			)


			let legend = []
			for (let i = months.length-1; i >= 0; i--) {
				legend.push(
					<div key={'legend' + months[i]} style={{ display: 'inline-block' }}>
						<div className="square" style={{ backgroundColor: DATA_COLORS.slice(-months.length).reverse()[i] }}></div>
						<div style={{ display: 'inline-block' }}>{months[i]}</div>
					</div>
				)
			}

			ret.push(
				<div key='legendComparisonCharts' className="col-md-12 text-center" style={{ marginBottom: '0.5em', paddingBottom: '1em' }}>{legend}</div>
			)
			ret.push(
				<div key='disclaimerComparisonCharts' className="text-right text-muted"><small>Визуально отображается сравнение первых пяти магазинов за период не более шести месяцев</small></div>
			)
			ret.push(
				<div key='headerComparisonKeyTargets' className="col-md-12" ><h4>Сравнение с целевыми показателями</h4></div>
			)
		}

		return (
			<div className="ComparisonChart">
				{ret}
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComparisonChart)
