import { createSelector } from 'reselect'
import { fromJS } from 'immutable'


export const _selectCommonData = (state) => state.getIn(['common', 'data', 'data'])

export const _selectCommonShops = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(0)
	}
)

export const _selectCommonDataRange = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(1)
	}
)

export const selectDataRange = createSelector(
	_selectCommonDataRange,
	(data) => {
		let firstDay = data.get('first_day').split(/-/g)
		let lastDay = data.get('last_day').split(/-/g)

		return fromJS({
			'firstDay': {
				year: firstDay[0],
				month: firstDay[1],
				day: firstDay[2],
			},
			'lastDay': {
				year: lastDay[0],
				month: lastDay[1],
				day: lastDay[2],
			},
		})
	}
)

export const selectKnownShops = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('area'),
				title: tuple.get('fullname'),
				city: tuple.get('city'),
				region: tuple.get('region'),
			}))
		}
		ret = ret.sort((a, b) => a.get('id') - b.get('id'))
		return ret
	}
)

export const selectKnownShopsAsMap = createSelector(
	selectKnownShops,
	(shops) => {
		let ret = fromJS({})
		for (let shop of shops) {
			ret = ret.set(shop.get('id'), shop)
		}
		return ret
	}
)

export const selectKnownCities = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('city'),
				title: tuple.get('city'),
			}))
		}

		ret = ret.groupBy(x => x.get('title')).map(x => x.first()).toList()
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))

		return ret
	}
)

export const selectShopsByCity = createSelector(
	selectKnownShops,
	selectKnownCities,
	(shops, cities) => {
		let ret = fromJS({})

		for (let city of cities) {
			let id = city.get('id')
			ret = ret.set(id, fromJS([]))

			let targetShops = []
			for (let s of shops) {
				if (s.get('city') === id) {
					targetShops.push(s.get('city'))
				}
			}

			for (let shop of shops) {
				if (targetShops.indexOf(shop.get('city')) >= 0) {
					ret = ret.update(id, (arr) => arr.push(shop))
				}
			}

		}

		return ret
	}
)

export const selectKnownRegions = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('region'),
				title: tuple.get('region'),
			}))
		}

		ret = ret.groupBy(x => x.get('title')).map(x => x.first()).toList()
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))

		return ret
	}
)
