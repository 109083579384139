import { generalModeChanged } from '../action'
import { loadAgg } from '../asyncAction'
import { selectFilterFull } from '../selector/filter-global'
import { selectVisibleRatings } from '../selector/rating-global'
import { selectMode } from '../selector/general-global'
import { selectKnownShops, selectKnownWorkers, selectKnownExecutors, selectKnownTypes } from '../selector/common-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import Rating from './Rating'

import React, { Component } from 'react'
import { connect } from 'react-redux'

const TITLES = {
	area:'По магазинам',
	executor:'По подрядчикам',
	worker:'По мастерам',
	what:'По типу установки',
}

class RatingRoot extends Component {
	render() {
		let p = this.props
		let fs = p.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])
		let visibleRatings = p.visibleRatings

		let tableContent = []
		visibleRatings.forEach(ratingInstance => {
			if (ratingInstance !== 'all') {

				tableContent.push(
					<td style={{ verticalAlign: 'top', padding: '1.0em' }} key={ratingInstance}>
						<div className="RatingRoot">
							<ErrorBoundary>
								<DataProvider
									dataKey={JSON.stringify(fs)}
									path={['install', 'rating', `dataBy_${ratingInstance}`]}
									sources={[loadAgg(ratingInstance)]}
									component={Rating}
									title={TITLES[ratingInstance]}
									varyParam={ratingInstance}
									valuesDefinition={p[ratingInstance].toJS()}
								/>
							</ErrorBoundary>
						</div>
					</td>
				)
			}
		})

		return (
			<table className="table" key='ratingsContainer' align="center" style={{ width: visibleRatings.size === 1 ? '50%' : '100%', tableLayout: 'fixed' }}>
				<tbody>
					<tr>
						{tableContent}
					</tr>
				</tbody>
			</table>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		mode: selectMode(state),
		filterSet: selectFilterFull(state),
		visibleRatings: selectVisibleRatings(state),
		area: selectKnownShops(state),
		executor: selectKnownExecutors(state),
		worker: selectKnownWorkers(state),
		what: selectKnownTypes(state),
		...ownProps
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (newMode) => {
			dispatch(generalModeChanged(newMode))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RatingRoot)
