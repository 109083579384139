import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'; import 'react-datetime/css/react-datetime.css'
import 'moment/locale/ru'


export default class MonthRange extends Component {
	constructor() {
		super()
		this.handleChangeFirst = this.handleChangeFirst.bind(this)
		this.handleChangeLast = this.handleChangeLast.bind(this)
	}

	handleChangeFirst(m) {
		if (typeof(m) === 'string') return  // date is invalid, skip
		let newValue = m.format('YYYY-MM')
		this.props.onChangeFirst(newValue)
	}

	handleChangeLast(m) {
		if (typeof (m) === 'string') return  // date is invalid, skip
		let newValue = m.format('YYYY-MM')
		this.props.onChangeLast(newValue)
	}

	render() {
		return (
			<div className="MonthRange" style={{ display: 'inline-block' }}>
				<div style={{ display: 'inline-block', width: '6.5em' }}>
					<Datetime
						dateFormat="YYYY-MM"
						timeFormat={false}
						value={this.props.valueFirstMonth}
						onChange={this.handleChangeFirst}
						locale="ru"
					/>
				</div>
				{' '}&mdash;{' '}
				<div style={{ display: 'inline-block', width: '6.5em' }}>
					<Datetime
						dateFormat="YYYY-MM"
						timeFormat={false}
						value={this.props.valueLastMonth}
						onChange={this.handleChangeLast}
						locale="ru"
					/>
				</div>
			</div>
		)
	}
}

MonthRange.propTypes = {
	valueFirstMonth: PropTypes.string.isRequired, // YYYY-MM
	valueLastMonth: PropTypes.string.isRequired, // YYYY-MM
	onChangeFirst: PropTypes.func.isRequired, // function(newValue), YYYY-MM
	onChangeLast: PropTypes.func.isRequired, // function(newValue), YYYY-MM
}
