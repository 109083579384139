import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { commentKeyChanged } from '../action'
import { selectCommentDetailedKeyAsField } from '../selector/comment-global'
import { selectKnownShops } from '../selector/common-global'


class CommentDetail extends Component {
	constructor() {
		super()
		this.handleHide = this.handleHide.bind(this)
	}

	handleHide() {
		this.props.onDetailedKeyChange(null)
	}

	render() {
		let data = this.props.data.toJS()[0]
		let knownShops = this.props.knownShops

		let shopCache = {}
		for (let shop of knownShops) {
			shopCache[shop.id] = shop
		}

		let count = 0
		let serverLimit = 200

		let dataBlock = data.map((comment) => {
			count += 1
			return (
				<tr key={count}>
					<td className="text-muted">{comment.area == null ? '' : shopCache[comment.area].title}</td>
					<td className="text-muted">{comment.worker || '—'}</td>
					<td className="text-muted">{comment.executor}</td>
					<td>{comment.comment}</td>
				</tr>
			)
		})

		let endBlock = (
			<div>
				<hr />
				<p>Показаны первые {count} комментариев.</p>
			</div>
		)

		return (
			<div className="CommentDetail">
				<p>
					{/* eslint-disable-next-line */}
					<a href="javascript://" onClick={this.handleHide}><i className="glyphicon glyphicon-remove"></i></a>
					{' '}
					{/* eslint-disable-next-line */}
					<a href="javascript://" onClick={this.handleHide}>Скрыть</a>
				</p>
				<table className="table table-condensed">
					<thead>
						<tr>
							<th style={{ width: '12em' }}>Магазин</th>
							<th style={{ width: '12em' }}>Мастер</th>
							<th style={{ width: '12em' }}>Подрядчик</th>
							<th>Комментарий</th>
						</tr>
					</thead>
					<tbody>
						{dataBlock}
					</tbody>
				</table>
				{count >= serverLimit ? endBlock : null}
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		knownShops: selectKnownShops(state).toJS(),
		detailedKey: selectCommentDetailedKeyAsField(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onDetailedKeyChange: (k) => {
			dispatch(commentKeyChanged(k))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CommentDetail)
