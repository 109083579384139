import { withPath } from '../../selector-util'
import * as source from './compare-local'

const PATH = ['install']
let unwrap = withPath(PATH)

export const selectCompareNextFilters = unwrap(source.selectCompareNextFilters)
export const selectCompareDisplayFilters = unwrap(source.selectCompareDisplayFilters)
export const selectCompareDisplayData = unwrap(source.selectCompareDisplayData)
export const selectCompareNextDataIsLoading = unwrap(source.selectCompareNextDataIsLoading)
export const selectCompareDisplayFiltersAsKeyTitle = unwrap(source.selectCompareDisplayFiltersAsKeyTitle)
export const selectCompareDisplayDataPretty = unwrap(source.selectCompareDisplayDataPretty)

export const selectComparisonCriteria = unwrap(source.selectComparisonCriteria)
export const selectCompareDisplayChartDataPretty = unwrap(source.selectCompareDisplayChartDataPretty)
