import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import stringify from 'csv-stringify'


const generate = (columns, data, fileName, charset='utf-8') => {
	const output = []
	const stringifier = stringify({
		delimiter: ';',
		cast: {
			number: (value) => ('' + value).replace('.',',')
		}
	})
	stringifier.on('readable', function () {
		let row;
		// eslint-disable-next-line
		while (row = stringifier.read()) {
			output.push(row)
		}
	})
	stringifier.on('error', function (err) {
		console.error(err.message)
	})
	stringifier.on('finish', () => {
		let ret = output.join('')
		let sourcePromise
		if (charset === 'utf-8') {
			sourcePromise = Promise.resolve(ret)
		} else {
			sourcePromise = import('iconv-lite').then(iconv => {
				return iconv.encode(ret, charset)
			})
		}

		sourcePromise.then(ret => {
			saveAs(
				new Blob(
					[ret],
					{ type: `text/csv;charset=${charset}` },
				),
				fileName
			);
		})
	})

	let cols = ['']
	for (let col of columns) {
		cols.push(col.title)
	}
	stringifier.write(cols)

	for (let row of data) {
		let current = []
		current.push(row.title)
		for (let col of columns) {
			current.push(row.values[col.key])
		}
		stringifier.write(current)
	}
	stringifier.end()
}


export default class ComparisonCSVExport extends Component {
	constructor() {
		super()
		this.handleExportUTF8 = this.handleExportUTF8.bind(this)
		this.handleExport1251 = this.handleExport1251.bind(this)

		this.state = { ret: null }
	}

	handleExportUTF8() {
		return generate(this.props.columns, this.props.data, this.props.fileName)
	}

	handleExport1251() {
		return generate(this.props.columns, this.props.data, this.props.fileName, 'cp1251')
	}

	render() {
		return (
			<div className="btn-group pull-right" style={{ marginTop: '0.1em' }}>
				<button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<i className="fa fa-download" /> Сохранить в CSV <span className="caret"></span>
				</button>
				<ul className="dropdown-menu">
					{/* eslint-disable-next-line */}
					<li><a href='javascript://' onClick={this.handleExport1251}><i className="fa fa-list" /> Windows-1251 (Excel)</a></li>
					{/* eslint-disable-next-line */}
					<li><a href='javascript://' onClick={this.handleExportUTF8}><i className="fa fa-list" /> UTF-8</a></li>
				</ul>
			</div>
		)
	}
}
