import App from './App'
import AuthWrapper from './generic/AuthWrapper'
import { rootReducer } from './reducer'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { isImmutable } from 'immutable'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
// import { HashRouter as Router, Route, Link, Redirect, Switch } from 'react-router-dom'

import logo from './logo.png'
import './index.css'


const stateTransformer = (state: any) => (isImmutable(state) ? state.toJS() : state)

const logger = createLogger({
	stateTransformer,
	collapsed: true,
	diff: (process.env.NODE_ENV !== 'production'),
})

let store = createStore(
	rootReducer,
	compose(
		applyMiddleware(logger),
		applyMiddleware(thunk),
	),
)

ReactDOM.render(
	<Provider store={store}>
		<AuthWrapper
			authURL='/api/auth'
			checkURL='/api/auth-check/'
			logoURL={logo}
			component={App}
		/>
	</Provider>,
	document.getElementById('root')
)
