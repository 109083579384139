import { createAction } from 'redux-actions'


// TODO: make global or remove
export const unsafeDirectSet = createAction('UNSAFE_DIRECT_SET', (key, value) => ({ key, value }))


export const filterAreaChanged = createAction('DELIVERY_FILTER_AREA_CHANGED', (value) => ({ value }))
export const filterExecutorChanged = createAction('DELIVERY_FILTER_EXECUTOR_CHANGED', (value) => ({ value }))
export const filterCityChanged = createAction('DELIVERY_FILTER_CITY_CHANGED', (value) => ({ value }))
export const filterRegionChanged = createAction('DELIVERY_FILTER_REGION_CHANGED', (value) => ({ value }))
export const filterClientTypeChange = createAction('DELIVERY_FILTER_CLIENT_TYPE_CHANGED', (value) => ({ value }))
export const filterOrderTypeChange = createAction('DELIVERY_FILTER_ORDER_TYPE_CHANGED', (value) => ({ value }))

export const filterDateFirstChanged = createAction('DELIVERY_FILTER_DATE_FIRST_CHANGED', (year, month, day) => ({ year, month, day }))
export const filterDateLastChanged = createAction('DELIVERY_FILTER_DATE_LAST_CHANGED', (year, month, day) => ({ year, month, day }))
export const filterDateJump = createAction('DELIVERY_FILTER_DATE_JUMP', (shortcut) => ({ shortcut }))

export const generalModeChanged = createAction('DELIVERY_GENERAL_MODE_CHANGED', (value) => ({ value }))
export const commentKeyChanged = createAction('DELIVERY_COMMENT_KEY_CHANGED', (value) => ({ value }))
export const commentTagsKeyChanged = createAction('DELIVERY_COMMENT_TAGS_CHANGED', (value) => ({ value }))

export const comparisonColumnAddCurrent = createAction('DELIVERY_COMPARISON_COLUMN_ADD_CURRENT', () => ({ /* O_O */ }))
export const comparisonColumnDelete = createAction('DELIVERY_COMPARISON_COLUMN_DELETE', (key) => ({ key }))
export const comparisonDataLoaded = createAction('DELIVERY_COMPARISON_DATA_LOADED', () => ({ /* O_O */ }))
export const comparisonCriteriaChanged = createAction('DELIVERY_COMPARISON_CRITERIA_CHANGED', (value) => ({ value }))

export const ratingCriteriaChanged = createAction('DELIVERY_RATING_CRITERIA_CHANGED', (value) => ({ value }))

export const commentKeyLoaded = createAction('DELIVERY_COMMEMT_KEY_LOADED', (value) => ({ value }))
