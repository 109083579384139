import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { selectAggDataKeyedDense } from '../selector/general-global'
import { selectCovidDataKeyedDense, selectCovidDataAgg, selectCovidDistanceData } from '../selector/general-global'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'
import { roundPolicy } from '../../selector-util'
import SortedKeysChartCJ from '../../generic/SortedKeysChartCJ'
import HorizontalBarChartCJ from '../../generic/HorizontalBarChartCJ'
import PieChartCJ from '../../generic/PieChart'
import ExportCSV from '../../generic/ExportCSV'

import './CovidChartsBlock.css'

const DATA_DETAILS = [
	{ key: 'countFullEquipment', title: 'И маска, и перчатки', color: '#41BB96' },
	{ key: 'countMaskOnly', title: 'Только маска', color: '#4472C4' },
	{ key: 'countGlovesOnly', title: 'Только перчатки', color: '#5B9BD5' },
	{ key: 'countNoEquipment', title: 'Ни маски, ни перчаток', color: '#EB5454' },
	{ key: 'countDoNotKnow', title: 'Затруднился ответить', color: '#A6A6A6' },
]

class CovidChartsBlock extends Component {
	render() {
		let horizontalBarData = this.props.covidDistanceData.toJS()
		let chartData = this.props.covidData.toJS()
		let pieData = this.props.covidDataAgg.toJS()

		let dataTitles = Array.from(DATA_DETAILS, entry => entry.title)
		let dataColors = Array.from(DATA_DETAILS, entry => entry.color)

		let exportCSVPieData = {}
		let exportCSVChartTitles = dataTitles.slice().reverse()
		exportCSVChartTitles.unshift('Дата доставки')
		let exportCSVPieOrder = {}
		DATA_DETAILS.forEach(entry => exportCSVPieOrder[entry.key] = entry.title)

		let legend = []
		for (let i = 0; i < dataTitles.length; i++) {
			legend.push(
				<div key={'legend' + i} style={{ display: 'inline-block' }}>
					<div className="square" style={{ backgroundColor: dataColors[i] }}></div>
					<div style={{ display: 'inline-block' }}>{dataTitles[i]}</div>
				</div>
			)
		}

		let countTotalPieData = Object.values(pieData).reduce((a, b) => a + b)
		if (countTotalPieData === 0) {
			return (
				<div className="CovidChartsBlock">
					<div className="col-md-12 text-center" style={{ marginBottom: '0.5em', paddingTop: '1em', paddingBottom: '1em' }}>Нет данных за выбранный период</div>
				</div>
			)
		} else {
			for (let key of Object.keys(pieData)) {
				exportCSVPieData[key] = [pieData[key], roundPolicy(pieData[key] * 100 / countTotalPieData)]
				pieData[key] = exportCSVPieData[key][1] //переводим данные для диаграммы в проценты
			}
			exportCSVPieData['countTotal'] = [countTotalPieData, '']
			exportCSVPieOrder['countTotal'] = 'Всего'
		}

		return (
			<div className="CovidChartsBlock">
				<h4>Соблюдение дистанции во время установки</h4>
				<div className="col-sm-12" style={{ paddingLeft: 0 }}>
					<HorizontalBarChartCJ
						dataObject={horizontalBarData}
						dataColor={['#41BB96', '#A6A6A6', '#EB5454']}
						dataPercent={true}
						dataStacked={true}
						decimalPlaces={1}
						turnXAxisOff={true}
						turnYAxisOff={true}
						turnTooltipsOff={true}
						height={100}
					/>
					<div className="col-sm-12 text-center" style={{ marginBottom: '0.5em', paddingBottom: '1em' }}>
						<div style={{ display: 'inline-block' }}>
							<div className="square" style={{ backgroundColor: '#41BB96' }}></div>
							<div style={{ display: 'inline-block' }}>Сотрудник соблюдал дистанцию 1,5 метра</div>
						</div>
						<div style={{ display: 'inline-block' }}>
							<div className="square" style={{ backgroundColor: '#A6A6A6' }}></div>
							<div style={{ display: 'inline-block' }}>Затрудняется ответить</div>
						</div>
						<div style={{ display: 'inline-block' }}>
							<div className="square" style={{ backgroundColor: '#EB5454' }}></div>
							<div style={{ display: 'inline-block' }}>Сотрудник не соблюдал дистанцию</div>
						</div>
					</div>
				</div>
				<div className="col-sm-9" style={{ paddingLeft: 0 }}>
					<h4>Динамика по дням</h4>
					<div className="sorted-stacked-chart">
						<SortedKeysChartCJ
							dataObject={chartData}
							dataColor={dataColors.slice().reverse()}
							dataPercent={true}
							dataStacked={true}
						/>
					</div>
					<div style={{ marginBottom: '0.5em' }} className='pull-right'>
						<ExportCSV
							titles={exportCSVChartTitles}
							data={chartData}
							fileName={'Equipment Details from ' + this.props.valueFirstMonth + ' to ' + this.props.valueLastMonth}
						/>
					</div>
				</div>
				<div className="col-sm-3" style={{ paddingLeft: 0 }}>
					<h4>Общая статистика</h4>
					<div className="PieChartCJ">
						<PieChartCJ
							dataObject={pieData}
							dataLabel={dataTitles}
							dataColor={dataColors}
							dataPercent={true}
							decimalPlaces={0}
							turnLegendOff={true}
						/>
					</div>
					<div style={{ marginBottom: '0.5em' }} className='pull-right'>
						<ExportCSV
							titles={['', 'Количество', '%']}
							rows={exportCSVPieOrder}
							data={exportCSVPieData}
							fileName={'Equipment Summary from ' + this.props.valueFirstMonth + ' to ' + this.props.valueLastMonth}
						/>
					</div>
				</div>
				<div className="col-sm-12 text-center" style={{ marginBottom: '0.5em', paddingTop: '1em', paddingBottom: '1em' }}>{legend}</div>

			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		covidData: selectCovidDataKeyedDense(state),
		covidDataAgg: selectCovidDataAgg(state),
		covidDistanceData: selectCovidDistanceData(state),

		valueFirstMonth: _selectFirstMonthFormatted(state),
		valueLastMonth: _selectLastMonthFormatted(state)
	}
}

export default connect(
	mapStateToProps,
)(CovidChartsBlock)
