import * as action from './action'
// import { joinOr } from '../generic/array'

import { fromJS } from 'immutable'
// import { combineReducers } from 'redux-immutable'
import { handleActions } from 'redux-actions'
import moment from 'moment'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted, selectFilterShort } from './selector/filter-local'
import { selectKnownShops } from './selector/common-local'

let now = moment()

const initialState = fromJS({
	test: 0,

	filter: {
		firstMonth: { year: now.clone().subtract(5, 'month').year(), month: now.clone().subtract(5, 'month').month() + 1 },
		lastMonth: { year: now.year(), month: now.month() + 1 },
		region: null,
		city: null,
		area: null,
		executor: null,
		worker: null,
		type: null,
	},

	summary: {
		data: {/* managed by DataProvider */},
	},

	common: {
		data: {/* managed by DataProvider */},
	},

	generalFilterTuples: {
		data: {/* managed by DataProvider */},
	},

	general: {
		mode: 'csi', // csi, csi_worker, nps, count
		data: {/* managed by DataProvider */},
	},

	compare: {
		show: false,

		displayFilters: [],
		displayData: {data:[{}, {}]},

		nextFilters: [{
			columnKey: '',
			filter: {},
		}],
		nextData: {/* managed by DataProvider */ },
		criteria: 'nps', // satisfaction, satisfaction_worker, nps
	},

	comment: {
		byCategory: {
			data: {/* managed by DataProvider */ },
		},
		detailed: {
			key: null, // "all"
			data: {/* managed by DataProvider */},
		},
	},

	rating: {
		criteria: 'csi', // csi, csi_worker, nps
		visibleRatings: ['area', 'executor'],
		dataBy_area: {/* managed by DataProvider */},
		dataBy_executor: {/* managed by DataProvider */},
		dataBy_worker: {/* managed by DataProvider */},
		dataBy_type: {/* managed by DataProvider */},
	},
})

const reducer = handleActions({
	[action.testKeyInc](state, action) {
		return state.updateIn(['test'], (x) => x+1)
	},

	[action.filterRegionChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'region'], value)
		})
	},

	[action.filterCityChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'city'], value)
		})
	},

	[action.filterAreaChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'area'], value)
		})
	},

	[action.filterExecutorChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'executor'], value)
		})
	},

	[action.filterWorkerChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'worker'], value)
		})
	},

	[action.filterTypeChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'type'], value)
		})
	},

	[action.filterDateFirstChanged](state, action) {
		return state.withMutations((state) => {
			let { year, month } = action.payload

			state.setIn(['filter', 'firstMonth', 'year'], year)
			state.setIn(['filter', 'firstMonth', 'month'], month)

			// if it's after last date, change last date as well
			let firstMonth = moment(_selectFirstMonthFormatted(state), "YYYY-MM")
			let lastMonth = moment(_selectLastMonthFormatted(state), "YYYY-MM")
			if (firstMonth.isAfter(lastMonth)) {
				state.setIn(['filter', 'lastMonth', 'year'], year)
				state.setIn(['filter', 'lastMonth', 'month'], month)
			}
		})
	},

	[action.filterDateLastChanged](state, action) {
		return state.withMutations((state) => {
			let { year, month } = action.payload

			state.setIn(['filter', 'lastMonth', 'year'], year)
			state.setIn(['filter', 'lastMonth', 'month'], month)

			// if it's before first date, change first date as well
			let firstMonth = moment(_selectFirstMonthFormatted(state), "YYYY-MM")
			let lastMonth = moment(_selectLastMonthFormatted(state), "YYYY-MM")
			if (lastMonth.isBefore(firstMonth)) {
				state.setIn(['filter', 'firstMonth', 'year'], year)
				state.setIn(['filter', 'firstMonth', 'month'], month)
			}
		})
	},

	[action.filterDateJump](state, action) {
		return state.withMutations((state) => {
			let { shortcut } = action.payload

			let t1 = now.clone()
			let t2 = now.clone()

			switch (shortcut) {
				case "reset":
					t1 = t1.subtract(5, 'month')
					break
				case "this-month":
					break
				case "prev-month":
					t1 = t1.subtract(1, 'month')
					t2 = t2.subtract(1, 'month')
					break
				case "this-quarter":
					t1 = t1.startOf('quarter')
					t2 = t1.clone().add(2, 'month')
					break
				case "prev-quarter":
					t1 = t1.subtract(3, 'month').startOf('quarter')
					t2 = t1.clone().add(2, 'month')
					break
				case "ytd":
					t1 = t1.startOf('year')
					break
				case "last-12-months":
					t1 = t1.subtract(11, 'month')
					break
				default:
					console.log(`Unknown shortcut: ${shortcut}`)
					return
			}

			state.setIn(['filter', 'firstMonth', 'year'], t1.year())
			state.setIn(['filter', 'firstMonth', 'month'], t1.month() + 1)

			state.setIn(['filter', 'lastMonth', 'year'], t2.year())
			state.setIn(['filter', 'lastMonth', 'month'], t2.month() + 1)
		})
	},

	[action.generalModeChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value
			state.setIn(['general', 'mode'], value)
		})
	},

	[action.commentKeyChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value

			let currentValue = state.getIn(['comment', 'detailed', 'key'])
			state.setIn(['comment', 'detailed', 'key'], (value === currentValue ? null : value))
		})
	},

	[action.ratingCriteriaChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value
			state.setIn(['rating', 'criteria'], value)
		})
	},

	[action.visibleRatingsChanged](state, action) {
		return state.withMutations((state) => {
			let targetValue = action.payload.value
			let currentValues = state.getIn(['rating', 'visibleRatings'])
			let possibleValues = ['area', 'executor', 'worker', 'what']

			let ret = currentValues
			if (targetValue) {
				if (currentValues.indexOf(targetValue) !== -1) {
					ret = ret.filter((x) => x !== targetValue)
				} else {
					ret = ret.push(targetValue)
				}
			} else {
				// if newValue is null, switch all
				ret = fromJS(currentValues.size === possibleValues.length ? [] : possibleValues)
			}
			ret = ret.sort((a, b) => possibleValues.indexOf(a) - possibleValues.indexOf(b))

			state.setIn(['rating', 'visibleRatings'], ret)
		})
	},

	[action.comparisonColumnAddCurrent](state, action) {
		return state.withMutations((state) => {
			let expectedFields = ['region', 'city', 'area', 'executor', 'worker', 'type']
			let fs = selectFilterShort(state).unfreeze(expectedFields)

			let knownShops = selectKnownShops(state)

			let shop_fullname = ''
			for (let s of knownShops) {
				if (s.get('id') === fs.area) {
					shop_fullname = s.get('title')
					break
				}
			}

			let ck = []
			expectedFields.forEach((key) => {
				if (fs[key]) {
					let value = fs[key]
					if (key === 'area') value = shop_fullname
					ck.push(value)
				}
			})
			let columnKey = ck.join(' / ')

			if (columnKey === '') {
				columnKey = 'Вся сеть'
			}

			let alreadyExists = false
			for (let s of state.getIn(['compare', 'nextFilters'])) {
				if (s.get('columnKey') === columnKey) {
					alreadyExists = true
					break
				}
			}
			if (alreadyExists) {
				return
			}

			state.updateIn(['compare', 'nextFilters'], value => value.push(fromJS({
				columnKey: columnKey,
				filter: fs,
			})))
		})
	},

	[action.comparisonColumnDelete](state, action) {
		return state.withMutations((state) => {
			let { key } = action.payload

			state.updateIn(['compare', 'nextFilters'], arr => arr.filter((value) => value.get('columnKey') !== key))
		})
	},

	[action.comparisonDataLoaded](state, action) {
		return state.withMutations((state) => {
			let nextFilters = state.getIn(['compare', 'nextFilters'])
			state.setIn(['compare', 'displayFilters'], nextFilters)

			let nextData = state.getIn(['compare', 'nextData'])
			state.setIn(['compare', 'displayData'], nextData)
		})
	},

	[action.comparisonCriteriaChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value
			state.setIn(['compare', 'criteria'], value)
		})
	},

}, initialState)

export default reducer
