import { roundPolicy } from '../../selector-util'

import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

export const _selectSummaryDataRaw = (state) => state.getIn(['install', 'summary', 'data', 'data']).get(0)

export const selectSummaryDataPretty = createSelector(
	_selectSummaryDataRaw,
	(data) => {
		const sumOf = (ilist, key) => ilist.reduce((acc, obj) => acc + obj.get(key), 0)

		let ret = {}

		let countData = data.get(0)
		console.log(countData)

		let count = sumOf(countData, 'count_total')

		let count_satisfied = sumOf(countData, 'count_satisfaction_yes')
		let count_satisfaction_effective = sumOf(countData, 'count_satisfaction_effective')

		let count_worker_satisfied = sumOf(countData, 'count_worker_yes')
		let count_worker_satisfaction_effective = sumOf(countData, 'count_worker_effective')

		let count_promoter = sumOf(countData, 'count_nps_promoter')
		let count_detractor = sumOf(countData, 'count_nps_detractor')
		let count_nps_effective = sumOf(countData, 'count_nps_effective')

		let csi = roundPolicy(count_satisfied * 100 / count_satisfaction_effective)
		let csi_worker = roundPolicy(count_worker_satisfied * 100 / count_worker_satisfaction_effective)
		let nps = roundPolicy((count_promoter - count_detractor) * 100 / count_nps_effective)

		let promoter = roundPolicy(count_promoter * 100 / count_nps_effective)
		let detractor = roundPolicy(count_detractor * 100 / count_nps_effective, 0)
		let neutral = 100 - promoter - detractor

		let satisfied = roundPolicy(count_satisfied * 100 / count_satisfaction_effective, 0)
		let dissatisfied = 100 - satisfied

		let worker_satisfied = roundPolicy(count_worker_satisfied * 100 / count_worker_satisfaction_effective, 0)
		let worker_dissatisfied = 100 - worker_satisfied

		ret = {
			count,
			satisfied, dissatisfied, promoter, neutral, detractor, worker_satisfied, worker_dissatisfied,
			csi, csi_worker, nps,
		}

		return fromJS(ret)
	}
)
