import { deepStrictEqual } from "assert"

class FilterSet {
	constructor(fields, required, parent, values) {
		let actualFields = fields.concat()
		let actualRequired = required.concat()

		if (parent) {
			actualFields = actualFields.concat(parent.fields)
			actualRequired = actualRequired.concat(parent.required)
		}

		for (let name of actualRequired) {
			if (!values.hasOwnProperty(name)) throw new Error(`Field '${name}' is mandatory`)
		}
		for (let key in values) {
			if (actualFields.indexOf(key) < 0) throw new Error(`Unknown field '${key}'`)
		}

		this._fields = actualFields
		this._values = values
	}

	unfreeze(expected=[]) {
		let a = this._fields.concat().sort()
		let b = expected.concat().sort()
		deepStrictEqual(a, b)
		return this._values
	}

	unsafeRead(silent=false) {
		if (!silent) {
			console.log('unsafeRead() is unsafe, use unfreeze() instead. Or, if you know what you are doing, set silent=true')
		}
		return this._values
	}
}

class FilterSetFactory {
	constructor(fields, required, parent) {
		this.fields = fields
		this.required = required
		this.parent = parent

		this.create = this.create.bind(this)
	}

	create(values) {
		return new FilterSet(this.fields, this.required, this.parent, values)
	}
}

export const createFilterSet = (fields, required=[], parent=null) => {
	let fsf = new FilterSetFactory(fields, required, parent)
	return fsf
}
