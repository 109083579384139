import { withPath } from '../../selector-util'
import * as source from './general-local'

const PATH = ['install']
let unwrap = withPath(PATH)

export const selectMode = unwrap(source.selectMode)
export const selectAggData = unwrap(source.selectAggData)
export const selectAggDataKeyed = unwrap(source.selectAggDataKeyed)
export const selectAggDataKeyedDense = unwrap(source.selectAggDataKeyedDense)

export const selectCovidDataAgg = unwrap(source.selectCovidDataAgg)
export const selectCovidDataKeyedDense = unwrap(source.selectCovidDataKeyedDense)
export const selectCovidDistanceData = unwrap(source.selectCovidDistanceData)
