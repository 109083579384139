import { createSelector } from 'reselect'


export const selectCommentDetailedKeyRaw = (state) => state.getIn(['comment', 'detailed', 'key'])

export const selectCommentDetailedKeyAsAgg = createSelector(
	selectCommentDetailedKeyRaw,
	(raw) => {
		let ret = raw
		if (ret !== null) ret = ret.replace('comment_', 'count_')
		return ret
	}
)

export const selectCommentDetailedKeyAsField = createSelector(
	selectCommentDetailedKeyRaw,
	(raw) => {
		let ret = raw
		if (ret !== null) ret = ret.replace('count_', 'comment_').replace('comment_comment_', '')
		return ret
	}
)
