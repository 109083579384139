import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { HorizontalBar } from 'react-chartjs-2'; import 'chartjs-plugin-datalabels'


const labelTransform = (x) => x.split(/-/g).reverse().join('.')

const roundPolicy = (x, dp) => Math.round(x * (10 ** dp)) / (10 ** dp)

const properMinus = (x) => x.replace(/-/g, '−')

export default class HorizontalBarChartCJ extends Component {
	render() {
		let d = this.props.dataObject

		let percentSign = (this.props.dataPercent ? '%' : '')
		let decimalPlaces = !!this.props.decimalPlaces ? this.props.decimalPlaces : 0
		let keys = Object.keys(d).sort()
		let values = []
		let max = 0

		// Для построения гистограмм с несколькими рядами данных необходимо передавать данные каждого ряда массивами
		// Ниже мы проверяем наличие массивов в полученных данных и определяем размерность (количество рядов-datasets'ов)
		let arrLength = null
		for (let key of keys) {
			if (Array.isArray(d[key])) {
				arrLength = d[key].length
			}
		}

		// При наличие массивов формируем данные в формат, необходимый для отрисовки (по рядам)
		if (!!arrLength) {
			for (let i = 0; i < arrLength; ++i) {
				let innervalues = []
				for (let key of keys) {
					let v = d[key]
					if (v == null) {
						innervalues.push(null)
					} else {
						innervalues.push(v[i])
						// Расчитываем максимальное значение шкалы по точке данных на случай если график в абсолютных величинах (по разному для гистограммы с накоплением и без)
						if (this.props.dataStacked) {
							if (v.reduce((a, b) => a + b) > max) max = v.reduce((a, b) => a + b)
						} else {
							if (v.reduce((a, b) => a > b ? a : b) > max) max = v.reduce((a, b) => a > b ? a : b)
						}
					}
				}
				values.push(innervalues)
			}
		} else {
			let innervalues = []
			for (let key of keys) {
				let v = d[key]
				innervalues.push(v)

				if (v > max) max = v
			}
			values.push(innervalues)
		}

		if (values.length === 0) return (
			<p className="text-center text-muted">Нет данных за выбранный период</p>
		)

		// Заполняем массив содержимым раздела datasets для каждого ряда данных
		// Для гистограмм с несколькими рядами обязательно для каждого указывать уникальный label
		let datasetsContent = []
		for (let i = 0; i < values.length; ++i) {
			datasetsContent.push({
				label: 'dataset' + i,
				borderWidth: 0,
				data: values[i],
				backgroundColor: Array.isArray(this.props.dataColor) ? this.props.dataColor[i] : values[i].map((x) => x < 0 ? '#eb5454' : this.props.dataColor)
			})
		}

		return (
			<div className="HorizontalBarChartCJ">
				<HorizontalBar
					data={{
						labels: keys.map(labelTransform),
						datasets: datasetsContent
					}}
					height={this.props.height ? this.props.height : 300}
					options={{
						maintainAspectRatio: false,
						// hover: {
						// 	mode: 'none'
						// },
						animation: {
							duration: 150,
						},
						legend: {
							display: false,
						},
						plugins: {
							datalabels: {
								anchor: this.props.dataStacked ? 'center' : 'end',
								align: this.props.dataStacked ? 'center' : 'end',
								color: this.props.dataStacked ? 'white' : undefined,
								font: {
									size: 14,
								},
								formatter: (value, context) => {
									if (value == null) return ''
									if (this.props.dataStacked && value === 0) return ''
									if (Number.isNaN(value)) return '' // TODO: hitting this branch means error in client code
									return properMinus(roundPolicy(value, decimalPlaces) + percentSign)
								}
							},
						},
						layout: {
							padding: {
								right: 30,
							},
						},
						scales: {
							xAxes: [{
								display: !this.props.turnXAxisOff,
								gridLines: {
									display: false,
									drawBorder: true,
									color: '#aaa',
								},
								ticks: {
									suggestedMin: 0,
									max: (this.props.dataPercent ? 100 : undefined),
								},
								stacked: this.props.dataStacked,
							}],
							yAxes: [{
								display: !this.props.turnYAxisOff,
								gridLines: {
									display: false,
									drawBorder: true,
									color: '#aaa',
								},
								ticks: {
									fontSize: 14,
								},
								stacked: this.props.dataStacked,
								maxBarThickness: this.props.dataStacked ? 50 : undefined, // number (pixels)
								cathegoryPercentage: this.props.dataStacked ? 1.2 : 0.9,
								barPercentage: this.props.dataStacked ? 1.2 : 0.9
							}],
						},
						tooltips: {
							enabled: !this.props.turnTooltipsOff,
							callbacks: {
								label: (tooltipItem, data) => {
									let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
									if (value == null) {
										return ''
									}
									return roundPolicy(value, 1)
								}
							}
						},
					}}
				/>
			</div>
		)
	}
}

HorizontalBarChartCJ.propTypes = {
	dataObject: PropTypes.object.isRequired, // key: value
}
