import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import { selectKnownShops } from '../selector/common-local'


export const _selectFiltersData = (state) => state.getIn(['generalFilterTuples', 'data', 'data'])

export const _selectFilterTuplesRaw = createSelector(
	_selectFiltersData,
	(data) => {
		if (!data) return null
		return data.get(0)
	}
)

export const selectAllFilterTuples = createSelector(
	selectKnownShops,
	_selectFilterTuplesRaw,
	(shops, filterPairs) => {

		let ret = fromJS([])

		for (let shop of shops) {
			for (let pair of filterPairs) {
				if (pair.get('area') === shop.get('id')) {
					ret = ret.push(fromJS({
						area: pair.get('area'),
						fullname: shop.get('title'),
						executor: pair.get('executor'),
						worker: pair.get('worker'),
						type: pair.get('what'),
						city: shop.get('city'),
						region: shop.get('region'),
					}))
				}
			}
		}
		return ret
	}
)
