import React, { Component } from 'react'

class SmartassList extends Component {
	constructor() {
		super()
		this.state = { current: [] }
	}

	static getDerivedStateFromProps(props, state) {
		let current = state.current
		let next = props.children

		if (next.length >= current.length) {
			return { current: next }
		} else {
			for (let i = 0; i < current.length; i++) {
				if (i < next.length) {
					current[i] = next[i]
				} else {
					current[i] = React.cloneElement(current[i], {style: {display: 'none'}})
				}
			}
			return { current: current }
		}
	}

	render() {
		return this.state.current
	}
}

export default SmartassList;



