import React, { Component } from 'react'
import { connect } from 'react-redux'

import { loadAgg } from '../asyncAction'
import { selectFilterFull } from '../selector/filter-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import CovidChartsBlock from './CovidChartsBlock'

const PATH = ['install', 'general', 'data']
const SOURCES = [ loadAgg() ]
//------------------------------------------------------------------------------
//
//
// ВНИМАНИЕ: данный компонент выгружает теже данные и по тому же пути, что и GeneralChartRoot
// так делать нельзя, но сделано так потому что блок временный, его скоро удалим, а если не удалим - надо переделать
// повторяю, сделано так в связи с временным характером CovidCharts и если до августа 2020 данный блок станет постоянным, необходимо привести выгрузку в порядок
//
//
// -----------------------------------------------------------------------------
class CovidChartsBlockRoot extends Component {
	render() {
		let fs = this.props.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

		return (
			<div className="CovidChartsBlockRoot">
				<div className="col-md-12">
					<ErrorBoundary>
						<DataProvider
							dataKey={JSON.stringify(fs)}
							path={PATH}
							sources={SOURCES}
							component={CovidChartsBlock}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		filterSet: selectFilterFull(state),
	}
}

export default connect(
	mapStateToProps,
)(CovidChartsBlockRoot)
