import { comparisonColumnDelete } from '../action'
import {
	selectCompareDisplayFiltersAsKeyTitle,
	selectCompareDisplayChartDataPretty,
	selectComparisonCriteria,
} from '../selector/compare-global'
import { selectLimitedMonthsFormatted } from '../selector/filter-global'

import React, { Component } from 'react'
import { connect } from 'react-redux'


class ComparisonDataProvider extends Component {
	render() {
		let p = this.props

		let data = {}
		let keyTitles = []
		for (let filter of p.kt) {
			if (filter.key !== "") {
				let d = p.data[filter.key]
				if (d) {
					let keyData = {}
					keyTitles.push(filter.key)
					for (let month of p.months) {
						if (d[month]) {
							switch (p.criteria) {
								case 'nps':
									keyData[month] = d[month].nps
									break
								case 'satisfaction':
									keyData[month] = d[month].satisfaction
									break
								case 'satisfaction_worker':
									keyData[month] = d[month].satisfaction_worker
									break
								default:
									throw new Error(`Unknown criteria: ${this.props.ratingCriteria}`)
							}
						} else {
							keyData[month] = null
						}
					}
					data[filter.key] = keyData
				}
			}
		}

		let Renderer = p.renderer
		return (<Renderer data={data} keytitles={keyTitles} months={p.months} onDelete={p.onDelete} />)
	}
}


const mapStateToProps = (state, ownProps) => {
	let kt = selectCompareDisplayFiltersAsKeyTitle(state)
	let months = selectLimitedMonthsFormatted(state)
	let data = selectCompareDisplayChartDataPretty(state)
	let criteria = selectComparisonCriteria(state)
	return {
		kt: kt.toJS(),
		months: months,
		data: data.toJS(),
		criteria: criteria,

		...ownProps
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onDelete: (rowspec) => {
			dispatch(comparisonColumnDelete(rowspec.key))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComparisonDataProvider)
