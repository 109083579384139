import { selectRatingCriteria } from '../selector/rating-global'
import TableOverObject from '../../generic/TableOverObject'
import ExportCSV from '../../generic/ExportCSV'

import { generalModeChanged } from '../action'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


const EXPORT_PARAMETER_TITLES = {
	area: 'Магазин',
	executor: 'Подрядчик',
	worker: 'Мастер',
	what: 'Тип установки',
	satisfaction: 'CSI',
	csi: 'CSI',
	csi_worker: 'CSI (по мастерам)',
	nps: 'NPS',
}

const EXPORT_VARYPARAM_NAME = {
	area: 'shops',
	executor: 'executors',
	worker: 'masters',
	what: 'type',
}

class Rating extends Component {
	render() {
		let source = this.props.data.get(0).toJS()

		let data = {}
		for (let d of source) {
			let key = d[this.props.varyParam]
			let value = null

			switch (this.props.ratingCriteria) {
			case "csi":
				if (d.count_satisfaction_effective === 0) {
					value = undefined
				} else {
					value = d.count_satisfaction_yes * 100 / d.count_satisfaction_effective
				}
				data[key] = value
				break
			case "csi_worker":
				if (d.count_worker_effective === 0) {
					value = undefined
				} else {
					value = d.count_worker_yes * 100 / d.count_worker_effective
				}
				data[key] = value
				break
			case "nps":
				if (d.count_nps_effective === 0) {
					value = undefined
				} else {
					value = (d.count_nps_promoter - d.count_nps_detractor) * 100 / d.count_nps_effective
				}
				data[key] = value
				break
			default:
				throw new Error(`Unknown criteria: ${this.props.ratingCriteria}`)
			}
		}

		let definition = []
		for (let vd of this.props.valuesDefinition) {
			if (data[vd.id] === undefined) {
				continue
			}
			definition.push({
				key: vd.id,
				title: vd.title,
			})
		}

		definition.sort((a, b) => {
			return data[b.key] - data[a.key]
		})

		let exportTitles = this.props.varyParam === 'area' ? ['Номер магазина', EXPORT_PARAMETER_TITLES[this.props.varyParam], EXPORT_PARAMETER_TITLES[this.props.ratingCriteria]] : [EXPORT_PARAMETER_TITLES[this.props.varyParam], EXPORT_PARAMETER_TITLES[this.props.ratingCriteria]]
		let exportData = {}
		if (this.props.varyParam === 'area') {
			for (let def of definition) {
				exportData[def.key] = [def.title, data[def.key]]
			}
		} else {
			exportData = data
		}

		return (
			<div className='Rating'>
				<div>
					<div style={{ marginBottom: '0.5em' }} className='pull-right'>
						<ExportCSV
							titles={exportTitles}
							data={exportData}
							fileName={EXPORT_VARYPARAM_NAME[this.props.varyParam] + ' ' + this.props.ratingCriteria + ' from ' + this.props.valueFirstMonth + ' to ' + this.props.valueLastMonth}
						/>
					</div>
					<p><b>{this.props.title}</b></p>
				</div>

				<TableOverObject
					definition={definition}
					data={data}
					maxValue={100}
					tableClass="table table-condensed"
					//rowClass={(crit, curr) => (crit.key === highlightKey ? 'bg-info' : '')}
				/>
			</div>
		)
	}
}

Rating.propTypes = {
	varyParam: PropTypes.string.isRequired,
	valuesDefinition: PropTypes.array.isRequired,  /* [{ id: <any>, title: <string> }, ...] */
	ratingCriteria: PropTypes.string.isRequired,
}


const mapStateToProps = (state, ownProps) => {
	return {
		ratingCriteria: selectRatingCriteria(state),
		valueFirstMonth: _selectFirstMonthFormatted(state),
		valueLastMonth: _selectLastMonthFormatted(state),

	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (newMode) => {
			dispatch(generalModeChanged(newMode))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Rating)
