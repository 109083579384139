import { INSTALL_TARGET_CSI, INSTALL_TARGET_CSI_WORKER, INSTALL_TARGET_NPS } from '../../config'
import { comparisonColumnDelete } from '../action'
import {
	selectCompareDisplayFiltersAsKeyTitle,
	selectCompareDisplayDataPretty,
} from '../selector/compare-global'
import { roundPolicy } from '../../selector-util'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'

import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'


const COLUMNS = [
	// `title` is implied
	// extension может принимать значения типов рендера, на данный момент это diff (с отклонением внизу),
	// nps-details (разбивка прогрессбара на категории НПС) и null
	{ key: 'csi', maxWidthPx: 180, title: 'CSI (в целом)', extension: 'diff', exportOnly: false },
	{ key: 'count_satisfaction_no', maxWidthPx: 60, title: 'CSI (в целом) - количество оценок 1-7', extension: null, exportOnly: true },
	{ key: 'count_satisfaction_yes', maxWidthPx: 60, title: 'CSI (в целом) - количество оценок 8-10', extension: null, exportOnly: true },
	{ key: 'csi_worker', maxWidthPx: 180, title: 'CSI (по мастерам)', extension: 'diff', exportOnly: false },
	{ key: 'count_worker_no', maxWidthPx: 60, title: 'CSI (по мастерам) - количество оценок 1-7', extension: null, exportOnly: true },
	{ key: 'count_worker_yes', maxWidthPx: 60, title: 'CSI (по мастерам) - количество оценок 8-10', extension: null, exportOnly: true },
	{ key: 'nps', maxWidthPx: 180, title: 'NPS', extension: 'diff', exportOnly: false },
	{ key: 'count_detractor', maxWidthPx: 60, title: 'Количество детракторов (1-6)', extension: null, exportOnly: true },
	{ key: 'count_neutral', maxWidthPx: 60, title: 'Количество нейтральных (7-8)', extension: null, exportOnly: true },
	{ key: 'count_promoter', maxWidthPx: 60, title: 'Количество промоутеров (9-10)', extension: null, exportOnly: true },
	{ key: 'nps_details', maxWidthPx: 180, title: 'Детали NPS', extension: 'nps-details', exportOnly: false },
	{ key: 'count', maxWidthPx: 120, title: 'Установок', extension: null, exportOnly: false },
]

const TITLE_TARGET = 'Целевые показатели'
const TITLE_OVERALL = 'Вся сеть'
const TITLE_AVERAGE = 'Среднее по выбранным'


const makeValues = (obj = {}) => {
	let ret = {}
	for (let col of COLUMNS) {
		if (obj.hasOwnProperty(col.key)) {
			ret[col.key] = obj[col.key]
		} else {
			ret[col.key] = null
		}
	}
	return ret
}


const postprocessAverages = (data) => {
	let count = data.filter((row) => row.tag === 'data').length
	if (count <= 1) {
		return
	}

	let averages = {
		tag: 'avg',
		title: TITLE_AVERAGE,
		values: makeValues(),
	}

	for (let col of COLUMNS) {
		let sum = null
		if (col.extension === 'nps-details') {
			console.log(data)
			sum = data.reduce((sum, d) => {
				if (d.tag === 'data') {
					sum.effective = sum.effective + d.values.count_promoter + d.values.count_neutral + d.values.count_detractor
					sum.promoter = sum.promoter + d.values.count_promoter
					sum.detractor = sum.detractor + d.values.count_detractor
				}
				return sum
			}, { 'effective': 0, 'promoter': 0, 'detractor': 0 })

			averages.values[col.key] = {
				'promoter': Math.round(sum.promoter * 100 / sum.effective),
				'neutral': 100 - Math.round(sum.promoter * 100 / sum.effective) - Math.round(sum.detractor * 100 / sum.effective),
				'detractor': Math.round(sum.detractor * 100 / sum.effective)
			}

		} else {
			sum = data.reduce((sum, d) => {
				if (d.tag === 'data') {
					sum = sum + d.values[col.key]
				}
				return sum
			}, 0)

			averages.values[col.key] = roundPolicy(sum / count)
		}
	}

	data.push(averages)
}


class ComparisonDataProvider extends Component {
	// constructor() {
	// 	super()
	// 	this.handleRowClicked = this.handleRowClicked.bind(this)
	// }

	render() {
		let p = this.props

		let data = []
		data.push({
			tag: 'target',
			title: TITLE_TARGET,
			values: makeValues({
				csi: INSTALL_TARGET_CSI,
				csi_worker: INSTALL_TARGET_CSI_WORKER,
				nps: INSTALL_TARGET_NPS,
			}),
		})
		for (let filter of p.kt) {
			let d = p.data[filter.key]
			let row = {
				tag: 'data',
				title: filter.key,
				values: makeValues(d),
			}
			if (filter.key === '') {
				row.title = TITLE_OVERALL
				row.tag = 'overall'
			}
			data.push(row)
		}

		data.forEach(d => {
			let count_effective = d.values.count_promoter + d.values.count_neutral + d.values.count_detractor
			if (d.tag !== 'target') {
				d.values.nps_details = {
					'promoter': Math.round(d.values.count_promoter * 100 / count_effective),
					'neutral': 100 - Math.round(d.values.count_promoter * 100 / count_effective) - Math.round(d.values.count_detractor * 100 / count_effective),
					'detractor': Math.round(d.values.count_detractor * 100 / count_effective)
				}
			} else {
				d.values.nps_details = null
			}
		})

		postprocessAverages(data)

		let Renderer = p.renderer
		let fileName = 'comparison from ' + p.valueFirstMonth + ' to ' + p.valueLastMonth + '.csv'
		return (<Renderer columns={COLUMNS} data={data} fileName={fileName} onDelete={p.onDelete} />)
	}
}


const mapStateToProps = (state, ownProps) => {
	let kt = selectCompareDisplayFiltersAsKeyTitle(state)
	let data = selectCompareDisplayDataPretty(state)

	return {
		kt: kt.toJS(),
		data: data.toJS(),
		valueFirstMonth: _selectFirstMonthFormatted(state),
		valueLastMonth: _selectLastMonthFormatted(state),

		...ownProps
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onDelete: (rowspec) => {
			dispatch(comparisonColumnDelete(rowspec.key))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComparisonDataProvider)
