import { comparisonCriteriaChanged } from '../action'
import { selectComparisonCriteria } from '../selector/compare-global'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


class ComparisonCriteriaSwitch extends Component {
	render() {
		return (
			<div className="ComparisonCriteriaSwitch">
				<div className="btn-group">
					<button
						type="button"
						className={"btn btn-info " + (this.props.value === 'nps' ? 'active' : '')}
						onClick={() => this.props.onChange('nps')}
					>&nbsp;&nbsp;NPS&nbsp;&nbsp;</button>
					<button
						type="button"
						className={"btn btn-info " + (this.props.value === 'satisfaction' ? 'active' : '')}
						onClick={() => this.props.onChange('satisfaction')}
					>&nbsp;&nbsp;CSI (в целом)&nbsp;&nbsp;</button>
					<button
						type="button"
						className={"btn btn-info " + (this.props.value === 'satisfaction_worker' ? 'active' : '')}
						onClick={() => this.props.onChange('satisfaction_worker')}
					>&nbsp;&nbsp;CSI (по мастерам)&nbsp;&nbsp;</button>
				</div>
			</div>
		)
	}
}

ComparisonCriteriaSwitch.propTypes = {
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
	return {
		value: selectComparisonCriteria(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (value) => {
			dispatch(comparisonCriteriaChanged(value))
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComparisonCriteriaSwitch)
