import BlackMagic from './BlackMagic'
import InstallRoot from './install/component/InstallRoot'
import ScrollButton from './generic/ScrollButton'

import React, { Component } from 'react'
import { HashRouter as Router, Route, Link, Redirect, Switch } from 'react-router-dom'

import './App.css'


export default class App extends Component {
	render() {
		let blackMagicBlock = null
		if (process.env.NODE_ENV === 'development') {
			blackMagicBlock = (
				<BlackMagic />
			)
		}

		return (
			<div className="App">
				<Router>
					<nav className="navbar navbar-default navbar-static-top">
						<div className="container">
							<ul className="nav navbar-nav navbar-left">
								<Switch>
									<Route path="/install/">
										<li className="active">
											<Link to="/install/">&nbsp;&nbsp;<i className="fa fa-tools" /> Услуги&nbsp;&nbsp;</Link>
										</li>
									</Route>
									<Route>
										<li>
											<Link to="/install/">&nbsp;&nbsp;<i className="fa fa-tools" /> Услуги&nbsp;&nbsp;</Link>
										</li>
									</Route>
								</Switch>
							</ul>
							{blackMagicBlock}
						</div>
					</nav>

					<Switch>
						<Route path="/install/" component={InstallRoot} />
						<Route><Redirect to="/install/" /></Route>
					</Switch>
				</Router>
				<ScrollButton />
			</div>
		)
	}
}
