import React, { Component } from 'react'

/*
Usage:

<Tabs
	tabs={{
		'welcome': (<WelcomeComponent />),
		'page2': (<div>whatever</div>),
	}}
	order={['welcome', 'page2']}
	default='welcome'
/>
*/
export default class Tabs extends Component {
	constructor(props) {
		super(props)
		this.state = { currentTab: props.default }

		this.onChange = this.onChange.bind(this)
	}

	onChange(event) {
		this.setState({ currentTab: event.target.dataset.target })
	}

	render() {
		return (
			<div className="Tabs">
				<div>
					<ul class="nav nav-tabs">
						{this.props.order.map((name) => {
							return (
								<li role="presentation" className={name === this.state.currentTab ? 'active': ''}>
									<a href="javascript://" onClick={this.onChange} data-target={name}>{name}</a>
								</li>
							)
						})}
					</ul>
				</div>
				<div style={{ paddingTop: '1em' }}>{this.props.tabs[this.state.currentTab]}</div>
			</div>
		)
	}
}
