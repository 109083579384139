import React, { Component } from 'react'
// import base64 from 'base-64'


class AuthWrapper extends Component {
	constructor() {
		super();

		this.handleUsernameChange = this.handleUsernameChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			mode: 'empty',
			username: '',
			password: '',
		}
	}

	handleUsernameChange(event) {
		this.setState({ username: event.target.value });
	}

	handlePasswordChange(event) {
		this.setState({ password: event.target.value });
	}

	handleSubmit(event) {
		event.preventDefault()

		let self = this

		// let headers = new Headers()
		// headers.append('Authorization', 'Basic ' + base64.encode(self.state.username + ":" + self.state.password))

		let formData = new FormData()
		formData.append('username', self.state.username)
		formData.append('password', self.state.password)

		fetch(this.props.authURL, {
			method: 'POST',
			// body: JSON.stringify({
			// 	username: self.state.username,
			// 	password: self.state.password,
			// }),
			body: formData,
			// method: 'GET',
			// headers: headers,
			mode: 'cors',
			credentials: 'include',
		})
		.then(function(response){
			if (response.ok) {
				self.setState({ mode: 'ok' });
			} else {
				self.setState({ mode: 'wrong-password' });
			}
		})
		.catch(function(err){
			self.setState({ mode: 'error' });
		});
	}

	componentDidMount() {
		let self = this;

		fetch(this.props.checkURL, {
			mode: 'cors',
			credentials: 'include',
		})
		.then(function(response){
			if (response.ok) {
				self.setState({ mode: 'ok' });
			} else {
				self.setState({ mode: 'login-form' });
			}
		})
		.catch(function(err){
			self.setState({ mode: 'error' });
		});
	}

	render() {
		if (this.state.mode === 'ok') {
			let Child = this.props.component
			return (<div className='AuthWrapper'><Child /></div>)
		}

		if (this.state.mode === 'empty') {
			return (<div className='AuthWrapper'><p className="text-center">Загрузка...</p></div>)
		}

		let statusRow = null

		if (this.state.mode === 'error') statusRow = (
			<p className="text-center text-danger">Ошибка при загрузке данных</p>
		)

		if (this.state.mode === 'wrong-password') statusRow = (
			<p className="text-center text-danger">Ошибка авторизации</p>
			//<p className="text-center text-danger">Неверное имя пользователя или пароль</p>
		)

		return (
			<div className='AuthWrapper'>
				<div className="container">
					<div className="row">
						<div className="col-md-4 col-md-offset-4">
							<hr />
							<div style={{ textAlign: 'center' }}>
								<img src={this.props.logoURL} alt="" style={{ maxWidth: '100%', maxHeight: '110px' }} />
							</div>
							<hr />
							<form onSubmit={this.handleSubmit}>
								<div className="form-group">
									{statusRow}
								</div>
								<div className="form-group">
									<label htmlFor="exampleInputEmail1">Логин</label>
									<input onChange={this.handleUsernameChange} className="form-control" />
								</div>
								<div className="form-group">
									<label htmlFor="exampleInputPassword1">Пароль</label>
									<input onChange={this.handlePasswordChange} type='password' className="form-control" />
								</div>
								<div style={{textAlign: 'center'}}>
									<button type="submit" className="btn btn-default btn-lg">Войти</button>
								</div>
								{/* <p>{this.state.username} || {this.state.password}</p> */}
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AuthWrapper;
