import { dataProviderReducerImmutable } from './generic/DataProvider'

// import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
// import { handleActions } from 'redux-actions'

import deliveryReducer from './delivery/reducer'
import installReducer from './install/reducer'
import pickupReducer from './pickup/reducer'


const chainReducers = (reducers) => (state, action) => {
	let currentState = state
	for (let r of reducers) {
		currentState = r(currentState, action)
	}
	return currentState
}

export const rootReducer = chainReducers([
	combineReducers({
		delivery: deliveryReducer,
		install: installReducer,
		pickup: pickupReducer,
	}),
	dataProviderReducerImmutable,
])
