import { selectFilterShort } from '../selector/filter-global'
import { selectKnownShops } from '../selector/common-global'
import { addKeys } from '../../generic/jsx.js'

import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class FilterCaption extends Component {
	render() {
		let p = this.props
		let fs = this.props.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type'])

		let executor = (fs.executor === '') ? null : fs.executor
		let area = (fs.area === '') ? null : fs.area
		let worker = (fs.worker === '') ? null : fs.worker
		let type = (fs.type === '') ? null : fs.type
		let city = (fs.city === '') ? null : fs.city
		let region = (fs.region === '') ? null : fs.region

		let ret = []

		if (city == null && region == null && executor == null && area == null && worker == null && type == null) {
			ret = [(<h2>Вся сеть</h2>)]
		} else {
			let shop = area
			if (area !== null) {
				for (let s of p.knownShops) {
					if (s.get('id') === area) {
						shop = s.get('title')
					}
				}
			}
			ret.push(<h2>{[region, city, shop, executor,  worker, type].filter(x => x !== null).join(' — ')}</h2>)
		}

		// let index = 0
		return (
			<div className="FilterCaption">
				{addKeys(ret)}
				<p>&nbsp;</p>
			</div>
		)
	}
}

FilterCaption.propTypes = {
}


const mapStateToProps = (state, ownProps) => {
	return {
		knownShops: selectKnownShops(state),

		filterSet: selectFilterShort(state),
	}
}

export default connect(
	mapStateToProps,
)(FilterCaption)
