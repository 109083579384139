import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

export const _selectCommonData = (state) => state.getIn(['common', 'data', 'data'])

export const _selectCommonAreasAndExecutors = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return null
		return data.get(0)
	}
)

export const _selectCommonShops = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return null
		return data.get(1)
	}
)

export const _selectCommonDataRange = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return null
		return data.get(2)
	}
)

export const _selectCommonOrderTypes = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return null
		return data.get(3)
	}
)

export const selectDataRange = createSelector(
	_selectCommonDataRange,
	(data) => {
		let firstDay = data.get('first_day').split(/-/g)
		let lastDay = data.get('last_day').split(/-/g)

		return fromJS({
			'firstDay': {
				year: firstDay[0],
				month: firstDay[1],
				day: firstDay[2],
			},
			'lastDay': {
				year: lastDay[0],
				month: lastDay[1],
				day: lastDay[2],
			},
		})
	}
)


export const selectKnownShops = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('area'),
				title: tuple.get('fullname'),
				city: tuple.get('city'),
				region: tuple.get('region'),
			}))
		}
		ret = ret.sort((a, b) => a.get('id') - b.get('id'))
		return ret
	}
)

export const selectOrderTypes = createSelector(
	_selectCommonOrderTypes,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get(0),
				title: tuple.get(1),
			}))
		}
		ret = ret.sort((a, b) => a.get('id') - b.get('id'))
		return ret
	}
)

// Returns all possible (region, city, area, executor) tuples. One of these tuples will be
// with empty string instead of executor name; area and city will always be set.
export const selectAllFilterPairs = createSelector(
	selectKnownShops,
	_selectCommonAreasAndExecutors,
	selectOrderTypes,
	(shops, areasAndExecutors, ordertypes) => {
		let ret = fromJS([])
		for (let shop of shops) {
			ret = ret.push(fromJS({
				area: shop.get('id'),
				fullname: shop.get('title'),
				city: shop.get('city'),
				region: shop.get('region'),
				executor: null,
				client_type: null,
				order_type: null,
				ordertypename: null,
			}))

			const INDEX_AREA = 0
			const INDEX_EXECUTOR = 1
			const INDEX_CLIENTTYPE = 2
			const INDEX_ORDERTYPE = 3

			const ordertypename = (id) => {
				for (let ot of ordertypes) {
					if (ot.get('id') === id) {
						return ot.get('title')
					}
				}
			}

			for (let executor of areasAndExecutors) {
				if (executor.get(INDEX_AREA) === shop.get('id')) {
					ret = ret.push(fromJS({
						area: shop.get('id'),
						fullname: shop.get('title'),
						city: shop.get('city'),
						region: shop.get('region'),
						executor: executor.get(INDEX_EXECUTOR),
						client_type: executor.get(INDEX_CLIENTTYPE),
						order_type: executor.get(INDEX_ORDERTYPE),
						ordertypename: ordertypename(executor.get(INDEX_ORDERTYPE)),
					}))
				}
			}
		}
		return ret
	}
)


export const selectKnownCities = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('city'),
				title: tuple.get('city'),
			}))
		}

		ret = ret.groupBy(x => x.get('title')).map(x => x.first()).toList()
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))

		return ret
	}
)


export const selectKnownRegions = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('region'),
				title: tuple.get('region'),
			}))
		}

		ret = ret.groupBy(x => x.get('title')).map(x => x.first()).toList()
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))

		return ret
	}
)


export const selectKnownExecutors = createSelector(
	_selectCommonAreasAndExecutors,
	(areasAndExecutors) => {
		let ret = []

		//const INDEX_AREA = 0
		const INDEX_EXECUTOR = 1

		for (let v of areasAndExecutors) {
			let executor = v.get(INDEX_EXECUTOR)
			if (ret.indexOf(executor) < 0) {
				ret.push(executor)
			}
		}

		return fromJS(ret.map((value) => ({
			id: value,
			title: value,
		})))
	}
)


export const selectShopsByExecutor = createSelector(
	_selectCommonAreasAndExecutors,
	selectKnownShops,
	selectKnownExecutors,
	(areasAndExecutors, shops, executors) => {
		let ret = fromJS({})

		const INDEX_AREA = 0
		const INDEX_EXECUTOR = 1

		for (let executor of executors) {
			let id = executor.get('id')
			ret = ret.set(id, fromJS([]))

			let targetShops = []
			for (let v of areasAndExecutors) {
				if (v.get(INDEX_EXECUTOR) === id) {
					targetShops.push(v.get(INDEX_AREA))
				}
			}

			for (let shop of shops) {
				if (targetShops.indexOf(shop.get('id')) >= 0) {
					ret = ret.update(id, (arr) => arr.push(shop))
				}
			}
		}

		return ret
	}
)


export const selectExecutorsByArea = createSelector(
	_selectCommonAreasAndExecutors,
	selectKnownShops,
	selectKnownExecutors,
	(areasAndExecutors, shops, executors) => {
		let ret = fromJS({})

		const INDEX_AREA = 0
		const INDEX_EXECUTOR = 1

		for (let shop of shops) {
			let id = shop.get('id')
			ret = ret.set(id, fromJS([]))

			let targetExecutors = []
			for (let v of areasAndExecutors) {
				if (v.get(INDEX_AREA) === id) {
					targetExecutors.push(v.get(INDEX_EXECUTOR))
				}
			}

			for (let executor of executors) {
				if (targetExecutors.indexOf(executor.get('id')) >= 0) {
					ret = ret.update(id, (arr) => arr.push(executor))
				}
			}
		}

		return ret
	}
)


export const selectShopsByCity = createSelector(
	selectKnownShops,
	selectKnownCities,
	(shops, cities) => {
		let ret = fromJS({})

		for (let city of cities) {
			let id = city.get('id')
			ret = ret.set(id, fromJS([]))

			let targetShops = []
			for (let s of shops) {
				if (s.get('city') === id) {
					targetShops.push(s.get('city'))
				}
			}

			for (let shop of shops) {
				if (targetShops.indexOf(shop.get('city')) >= 0) {
					ret = ret.update(id, (arr) => arr.push(shop))
				}
			}

		}

		return ret
	}
)

// The functions below are currently unused.

export const selectExecutorsByCity = createSelector(
	_selectCommonAreasAndExecutors,
	selectKnownShops,
	selectKnownExecutors,
	selectKnownCities,
	(areas, shops, executors, cities) => {
		let ret = fromJS({})

		const INDEX_AREA = 0
		const INDEX_EXECUTOR = 1

		for (let city of cities) {
			let id = city.get('id')
			ret = ret.set(id, fromJS([]))

			let targetShops = []
			for (let s of shops) {
				if (s.get('city') === id) {
					targetShops.push(s.get('id'))
				}
			}

			let targetAreas = []
			for (let a of areas) {
				if (targetShops.indexOf(a.get(INDEX_AREA)) >= 0) {
					targetAreas.push(a.get(INDEX_EXECUTOR))
				}
			}

			for (let executor of executors) {
				if (targetAreas.indexOf(executor.get('id')) >= 0) {
					ret = ret.update(id, (arr) => arr.push(executor))
				}
			}

		}

		return ret
	}
)


export const selectCitiesByExecutor = createSelector(
	_selectCommonAreasAndExecutors,
	selectKnownShops,
	selectKnownExecutors,
	selectKnownCities,
	(areas, shops, executors, cities) => {
		let ret = fromJS({})

		const INDEX_AREA = 0
		const INDEX_EXECUTOR = 1

		for (let executor of executors) {
			let id = executor.get('id')
			ret = ret.set(id, fromJS([]))

			let targetAreas = []
			for (let a of areas) {
				if (a.get(INDEX_EXECUTOR) === id) {
					targetAreas.push(a.get(INDEX_AREA))
				}
			}

			let targetCities = []
			for (let s of shops) {
				if (targetAreas.indexOf(s.get('id')) >= 0) {
					targetCities.push(s.get('city'))
				}
			}

			for (let city of cities) {
				if (targetCities.indexOf(city.get('id')) >= 0) {
					ret = ret.update(id, (arr) => arr.push(city))
				}
			}

		}

		return ret
	}
)
