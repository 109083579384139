import React, { Component } from "react"

import "./ScrollButton.css"


class ScrollButton extends Component {
	constructor() {
		super()
		this.handleScroll = this.handleScroll.bind(this)
		this.scrollToTop = this.scrollToTop.bind(this)

		this.state = { scrollTop: 0 }
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll)
	}

	handleScroll() {
		this.setState({
			scrollTop: document.documentElement.scrollTop,
		})
	}

	scrollToTop() {
		window.scrollTo({ top: 0, behavior: "smooth" })
	}

	render() {
		let isVisible = (this.state.scrollTop > 100)
		return (
			<button className="ScrollButton" style={{ display: ( isVisible ? 'block' : 'none' ) }} onClick={this.scrollToTop}>
				<i className="fa fa-chevron-circle-up" />
			</button>
		)
	}
}

export default ScrollButton