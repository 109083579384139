import React, { Component } from 'react'
import { connect } from 'react-redux'

import { loadKnownShops, loadSpecificField, loadDataRange } from '../asyncAction'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import Install from './Install'

const PATH = ['install', 'common', 'data']
const SOURCES = [ loadKnownShops, loadSpecificField('worker'), loadSpecificField('executor'), loadSpecificField('what'), loadDataRange ]

class InstallRoot extends Component {
	render() {
		return (
			<div className="InstallRoot">
				<div className="col-md-12">
					<ErrorBoundary>
						<DataProvider
							dataKey={``}
							path={PATH}
							sources={SOURCES}
							component={Install}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InstallRoot)
