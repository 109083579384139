import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createAction, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { SpinnerCircular } from 'spinners-react'

import './DataProvider.css'


export const loadStarted = createAction('LOAD_STARTED', (key, dataKey) => ({ key, dataKey }))
export const loadSuccess = createAction('LOAD_SUCCESS', (key, data) => ({ key, data }))
export const loadFailed = createAction('LOAD_FAILED', (key, error) => ({ key, error }))

export const dataProviderReducerImmutable = handleActions({
	[loadStarted](state, action) {
		return state.withMutations((state) => {
			let key = action.payload.key
			let dataKey = action.payload.dataKey

			state.setIn(key.concat(['loading']), true)
			state.setIn(key.concat(['error']), null)
			state.setIn(key.concat(['lastDataKey']), dataKey)
		})
	},
	[loadSuccess](state, action) {
		return state.withMutations((state) => {
			let key = action.payload.key
			let data = fromJS(action.payload.data)

			state.setIn(key.concat(['loading']), false)
			state.setIn(key.concat(['data']), data)
		})
	},
	[loadFailed](state, action) {
		return state.withMutations((state) => {
			let key = action.payload.key
			let error = action.payload.error

			state.setIn(key.concat(['loading']), false)
			state.setIn(key.concat(['error']), error)
		})
	},
}, null)


class DataProvider extends Component {
	constructor() {
		super()
		this.fetchData = this.fetchData.bind(this)
	}

	fetchData() {
		this.props.onStart()

		this.props.dispatchThunk((dispatch, getState) => {
			let promises = []
			for (let fn of this.props.sources) {
				promises.push(fn(dispatch, getState))
			}

			// TODO: discard data (and errors) if dataKey has expired

			Promise.all(promises).then((data) => {
				this.props.onSuccess(data)
			}).catch((error) => {
				this.props.onError(error)
			})
		})
	}

	componentDidMount() {
		if (this.props.lastDataKey !== this.props.dataKey) {
			console.info(`DataProvider at /${this.props.path.join('/')}: data keys differ, re-fetch (in componentDidMount)`)
			this.fetchData()
		}
	}

	componentDidUpdate() {
		// console.info(`DataProvider at /${this.props.path}: componentDidUpdate`)
		if (this.props.lastDataKey !== this.props.dataKey) {
			console.info(`DataProvider at /${this.props.path.join('/')}: data keys differ, re-fetch`)
			this.fetchData()
		}
	}

	render() {
		let Target = this.props.component

		let upperPart = null
		let lowerPart = null

		if (!!this.props.data) {
			lowerPart = (
				<div className="child"><Target {...this.props} /></div>
			)

			if (this.props.error) upperPart = (
				<div>При загрузке данных произошла ошибка</div>
			)

			if (this.props.loading) {
				lowerPart = (
					<div className="child loading"><Target {...this.props} /></div>
				)
			}
		} else {
			if (this.props.loading) {
				// upperPart = (<div>Загрузка...</div>)
				upperPart = (
					<div style={{ textAlign: 'center' }}>
						<SpinnerCircular size={55} thickness={100} speed={147} color="rgba(65, 187, 150, 1)" secondaryColor="rgba(255, 255, 255, 1)" />
					</div>
				)
			}

			if (this.props.error) upperPart = (
				<div>При загрузке данных произошла ошибка</div>
			)
		}

		return (
			<div className="DataProvider">
				{upperPart}
				{lowerPart}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		loading: state.getIn(ownProps.path.concat(['loading'])),
		error: state.getIn(ownProps.path.concat(['error'])),
		data: state.getIn(ownProps.path.concat(['data'])),
		lastDataKey: state.getIn(ownProps.path.concat(['lastDataKey'])),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onStart: () => {
			dispatch(loadStarted(ownProps.path, ownProps.dataKey))
		},
		onSuccess: (data) => {
			dispatch(loadSuccess(ownProps.path, data))

			if (!!ownProps.onLoaded) {
				ownProps.onLoaded()
			}
		},
		onError: (error) => {
			dispatch(loadFailed(ownProps.path, error))
		},

		dispatchThunk: (thunk) => {
			dispatch(thunk)
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataProvider)
