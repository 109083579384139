import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'; import 'chartjs-plugin-datalabels'


const labelTransform = (x) => x.split(/-/g).reverse().join('.')

const roundPolicy = (x, dp) => Math.round(x * (10 ** dp)) / (10 ** dp)


export default class PieChartCJ extends Component {
	render() {
		let d = this.props.dataObject
		let keys = Object.keys(d)
		let dataLabel = this.props.dataLabel

		let percentSign = (this.props.dataPercent ? '%' : '')
		let decimalPlaces = !!this.props.decimalPlaces ? this.props.decimalPlaces : 0

		let values = []
		for (let key of keys) {
			let v = d[key]
			values.push(v)
		}

		if (values.length === 0) return (
			<p className="text-left text-muted">Нет данных за выбранный период</p>
		)

		return (
			<div className="PieChartCJ">
				<Pie
					data={{
						labels: dataLabel.map(labelTransform),
						datasets: [{
							borderWidth: 0,
							data: values,
							backgroundColor: this.props.dataColor
						}]
					}}
					// width={100}
					height={300}
					options={{
						maintainAspectRatio: false,
						hover: {
							mode: 'none'
						},
						animation: {
							duration: 150,
						},
						rotation: -0.25 * Math.PI,
						legend: {
							display: !this.props.turnLegendOff,
							position: 'bottom',
							labels: {
								fontSize: 14,
							}
						},
						plugins: {
							datalabels: {
								anchor: 'end',
								align: 'end',
								font: {
									size: 14,
								},
								formatter: (value, context) => {
									if (value == null) return ''
									return roundPolicy(value, decimalPlaces) + percentSign
								}
							},
						},
						layout: {
							padding: {
								top: 40,
								bottom: 40,
							},
						},
						tooltips: {
							enabled: true,
							callbacks: {
								label: (tooltipItem, data) => {
									let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
									if (value == null) {
										return ''
									}
									return roundPolicy(value, 1) + percentSign
								}
							}
						},
					}}
				/>
			</div>
		)
	}
}

PieChartCJ.propTypes = {
	dataObject: PropTypes.object.isRequired, // key: value
}
