import { pad, monthSequence, formatDay } from '../../selector-util'
import { FullFilterSet, ShortFilterSet } from '../filter'

import { createSelector } from 'reselect'
import * as moment from 'moment'


export const _selectFilterRaw = (state) => state.getIn(['filter'])

export const selectFilterShort = createSelector(
	_selectFilterRaw,
	(raw) => ShortFilterSet.create(raw.delete('firstDay').delete('lastDay').toJS()),
)
export const selectFilterFull = createSelector(
	_selectFilterRaw,
	(raw) => FullFilterSet.create(raw.toJS()),
)

export const _selectFirstMonthFormatted = (state) => {
	let y = _selectFilterRaw(state).getIn(['firstDay', 'year'])
	let m = _selectFilterRaw(state).getIn(['firstDay', 'month'])
	return y + '-' + pad(m)
}

export const _selectLastMonthFormatted = (state) => {
	let y = _selectFilterRaw(state).getIn(['lastDay', 'year'])
	let m = _selectFilterRaw(state).getIn(['lastDay', 'month'])
	return y + '-' + pad(m)
}

export const selectLimitedMonthsFormatted = createSelector(
	_selectFilterRaw,
	(raw) => {
		let ret = []
		let firstDate = new Date(raw.getIn(['firstDay', 'year']), raw.getIn(['firstDay', 'month']) - 1)
		let lastDate = new Date(raw.getIn(['lastDay', 'year']), raw.getIn(['lastDay', 'month']) - 1)
		let differMonths = ((lastDate.getFullYear() - firstDate.getFullYear()) * 12 + (lastDate.getMonth() - firstDate.getMonth()) + 1)
		let monthLimit = (differMonths > 6) ? 6 : differMonths
		for (let i = 0; i < monthLimit; i++) {
			let date = new Date(raw.getIn(['lastDay', 'year']), raw.getIn(['lastDay', 'month']) - 1)
			date.setMonth(date.getMonth() - i)
			ret.push(date.getFullYear() + '-' + pad(date.getMonth() + 1))
		}
		return ret
	}
)

export const selectTargetGrouping = createSelector(
	_selectFilterRaw,
	(dates) => {
		let y1 = dates.getIn(['firstDay', 'year'])
		let m1 = dates.getIn(['firstDay', 'month'])

		let y2 = dates.getIn(['lastDay', 'year'])
		let m2 = dates.getIn(['lastDay', 'month'])

		return ((y1 === y2) && (m1 === m2)) ? 'day' : 'month'
	}
)

export const selectDateKeysList = createSelector(
	selectTargetGrouping,
	_selectFilterRaw,
	(mode, filterRaw) => {
		if (mode === 'day') {
			let y = filterRaw.getIn(['firstDay', 'year'])
			let m = filterRaw.getIn(['firstDay', 'month'])
			let daysInThisMonth = moment.utc({ year: y, month: m - 1 }).daysInMonth()
			let days = []
			for (let d = 1; d <= daysInThisMonth; d++) {
				days.push(formatDay(y, m, d))
			}
			return days
		} else {
			let y1 = filterRaw.getIn(['firstDay', 'year'])
			let m1 = filterRaw.getIn(['firstDay', 'month'])

			let y2 = filterRaw.getIn(['lastDay', 'year'])
			let m2 = filterRaw.getIn(['lastDay', 'month'])

			return monthSequence(m1, y1, m2, y2)
		}
	}
)
