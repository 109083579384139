import React, { Component } from 'react'
import { connect } from 'react-redux'

import { loadSummaryData } from '../asyncAction'
import { selectFilterFull } from '../selector/filter-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import Summary from './Summary'


const PATH = ['install', 'summary', 'data']
const SOURCES = [ loadSummaryData ]

class SummaryRoot extends Component {
	render() {
		let fs = this.props.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

		return (
			<div className="SummaryRoot">
				<div className="col-md-12">
					<ErrorBoundary>
						<DataProvider
							dataKey={JSON.stringify(fs)}
							path={PATH}
							sources={SOURCES}
							component={Summary}
							onLoaded={this.props.onLoaded}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		filterSet: selectFilterFull(state),
	}
}

export default connect(
	mapStateToProps,
)(SummaryRoot)
