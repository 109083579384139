import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { comparisonDataLoaded } from '../action'
import { loadComparisonData } from '../asyncAction'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'
import { selectCompareNextFilters } from '../selector/compare-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import ComparisonBlock from './ComparisonBlock'
// import Dummy from './generic/Dummy'


const PATH = ['install', 'compare', 'nextData']
const SOURCES = [ loadComparisonData ]

class ComparisonRoot extends Component {
	render() {
		return (
			<div className="ComparisonRoot">
				<div className="col-md-12">
					<ErrorBoundary>
						<DataProvider
							dataKey={this.props.dataKey}
							path={PATH}
							sources={SOURCES}
							component={ComparisonBlock}
							onLoaded={this.props.onLoaded}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	let firstMonthFormatted = _selectFirstMonthFormatted(state)
	let lastMonthFormatted = _selectLastMonthFormatted(state)

	let filters = selectCompareNextFilters(state)

	let dataKey = [ firstMonthFormatted, lastMonthFormatted ]
	for (let filter of filters) {
		dataKey.push(filter.get('columnKey'))
	}
	dataKey = dataKey.join(' | ')

	return {
		dataKey,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onLoaded: () => {
			dispatch(comparisonDataLoaded())
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComparisonRoot)
