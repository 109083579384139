import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

export const _selectCommonData = (state) => state.getIn(['common', 'data', 'data'])

export const _selectCommonShops = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(0)
	}
)

export const _selectCommonWorkers = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(1)
	}
)

export const _selectCommonExecutors = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(2)
	}
)

export const _selectCommonTypes = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(3)
	}
)

export const _selectCommonDataRange = createSelector(
	_selectCommonData,
	(data) => {
		if (!data) return fromJS([])
		return data.get(4)
	}
)


export const selectKnownShops = createSelector(
	_selectCommonShops,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple.get('area'),
				title: tuple.get('fullname'),
				city: tuple.get('city'),
				region: tuple.get('region'),
			}))
		}
		ret = ret.sort((a, b) => a.get('id') - b.get('id'))
		return ret
	}
)

export const selectKnownShopsAsMap = createSelector(
	selectKnownShops,
	(shops) => {
		let ret = fromJS({})
		for (let shop of shops) {
			ret = ret.set(shop.get('id'), shop)
		}
		return ret
	}
)

export const selectKnownWorkers = createSelector(
	_selectCommonWorkers,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple,
				title: tuple,
			}))
		}
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))
		return ret
	}
)

export const selectKnownExecutors = createSelector(
	_selectCommonExecutors,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple,
				title: tuple,
			}))
		}
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))
		return ret
	}
)

export const selectKnownTypes = createSelector(
	_selectCommonTypes,
	(data) => {
		let ret = fromJS([])
		for (let tuple of data) {
			ret = ret.push(fromJS({
				id: tuple,
				title: tuple,
			}))
		}
		ret = ret.sort((a, b) => a.get('title').localeCompare(b.get('title')))
		return ret
	}
)

export const selectDataRange = createSelector(
	_selectCommonDataRange,
	(data) => {
		let firstDay = data.get('first_day').split(/-/g)
		let lastDay = data.get('last_day').split(/-/g)

		return fromJS({
			'firstDay': {
				year: firstDay[0],
				month: firstDay[1],
				day: firstDay[2],
			},
			'lastDay': {
				year: lastDay[0],
				month: lastDay[1],
				day: lastDay[2],
			},
		})
	}
)
