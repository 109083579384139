import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadAgg } from '../asyncAction'
import { selectFilterFull } from '../selector/filter-global'
import { selectCommentDetailedKeyAsField } from '../selector/comment-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import Comments from './Comments'
// import Dummy from '../../generic/Dummy'


const PATH = ['install', 'comment', 'byCategory', 'data']
const SOURCES = [ loadAgg('sum') ]

class CommentsRoot extends Component {
	render() {
		let p = this.props
		let fs = p.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

		return (
			<div className="CommentsRoot">
				<div className="col-md-12">
					<ErrorBoundary>
						<DataProvider
							dataKey={JSON.stringify(fs)}
							path={PATH}
							sources={SOURCES}
							component={Comments}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		detailedKey: selectCommentDetailedKeyAsField(state),
		filterSet: selectFilterFull(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CommentsRoot)
