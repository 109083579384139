import React from 'react'
import './QuestionIcon.css'

const tooltips = {
	'nps': 'Индекс лояльности. Рассчитывается как разница долей промоутеров (оценки 9-10) и детракторов (оценки 1-6).',
	'csi': 'Индекс удовлетворенности. Рассчитывается как доля оценок 8-10 из всех оценок.',
	'оценка качества доставки': 'Индекс удовлетворенности. Рассчитывается как доля оценок 8-10 из всех оценок.',
	'оценка качества самовывоза': 'Индекс удовлетворенности. Рассчитывается как доля оценок 8-10 из всех оценок.',
	'csi (по мастерам)': 'Индекс удовлетворенности. Рассчитывается как доля оценок 8-10 из всех оценок.',
	'csi (в целом)': 'Индекс удовлетворенности. Рассчитывается как доля оценок 8-10 из всех оценок.',
	'детали nps': 'Зеленый - промоутеры. Серый - нейтральные. Красный - детракторы.',
}

function QuestionIcon(props) {
	return (
		<span tooltip={tooltips[props.title.toLowerCase()] || ''}>{props.title} <i className="fa fa-question"></i></span>
	)
}

export default QuestionIcon
