import { comparisonColumnAddCurrent } from '../action'
import { selectCompareNextDataIsLoading } from '../selector/compare-global'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


class CompareButton extends Component {
	render() {
		if (this.props.loading) return (
			<span className="CompareButton">
				<i className="fa fa-search-plus" />
				{' '}
				Добавить в сравнение
			</span>
		)

		return (
			<span className="CompareButton">
				{/* eslint-disable-next-line */}
				<a href="javascript://" onClick={this.props.onClick}><i className="fa fa-search-plus" /></a>
				{' '}
				{/* eslint-disable-next-line */}
				<a href="javascript://" onClick={this.props.onClick}>Добавить в сравнение</a>
			</span>
		)
	}
}

CompareButton.propTypes = {
	onClick: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
	let loading = selectCompareNextDataIsLoading(state)
	return {
		loading,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onClick: () => {
			dispatch(comparisonColumnAddCurrent())
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CompareButton)
