let prefix = '/api'
if (process.env.NODE_ENV === 'production') {
	prefix = '/api'  // possibly other prefix
}

export const API_PREFIX = prefix


export const MODES = [
	{ key: 'satisfaction', title: 'Оценка качества доставки' },
	// { key: 'nps', title: 'Индекс NPS' },
	{ key: 'count', title: 'Количество доставок' },
	{ key: 'effective', title: 'Количество опрошенных клиентов' },
]

export const INSTALL_MODES = [
	{ key: 'csi', title: 'Уровень CSI (в целом)' },
	{ key: 'csi_worker', title: 'Уровень CSI (по мастерам)' },
	{ key: 'nps', title: 'Индекс NPS' },
	{ key: 'count', title: 'Собрано отзывов' },
]

export const PICKUP_MODES = [
	{ key: 'satisfaction', title: 'Оценка качества самовывоза' },
	// { key: 'nps', title: 'Индекс NPS' },
	{ key: 'count', title: 'Количество выданных заказов' },
	{ key: 'effective', title: 'Количество опрошенных клиентов' },
]

export const INSTALL_TYPES = [
	{ key: 'kitchen', title: 'Кухни' },
	{ key: 'door', title: 'Двери' },
]

// Use the new row-oriented comparison built with react-table v6.
export const USE_EXPERIMENTAL_COMPARISON = true

export const DELIVERY_TARGET_SATISFACTION = 93
export const DELIVERY_TARGET_NPS = 75

export const PICKUP_TARGET_SATISFACTION = 93
export const PICKUP_TARGET_NPS = 80

export const INSTALL_TARGET_CSI = 95
export const INSTALL_TARGET_CSI_WORKER = 95
export const INSTALL_TARGET_NPS = 85
