import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { generalModeChanged } from '../action'
import { selectAggDataKeyedDense } from '../selector/general-global'

import { selectFilterShort, _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'
import { selectMode } from '../selector/general-global'
import { roundPolicy } from '../../selector-util'
import SortedKeysChart from '../../generic/SortedKeysChartCJ'


class GeneralChart extends Component {
	render() {
		let sourceData = this.props.data.toJS()
		let data = {}
		for (let key of Object.keys(sourceData)) {
			if (this.props.mode === 'csi') {
				if (sourceData[key].count_satisfaction_effective !== 0) {
					data[key] = sourceData[key].count_satisfaction_yes * 100 / sourceData[key].count_satisfaction_effective
				} else {
					data[key] = null
				}
			}
			if (this.props.mode === 'csi_worker') {
				if (sourceData[key].count_worker_effective !== 0) {
					data[key] = sourceData[key].count_worker_yes * 100 / sourceData[key].count_worker_effective
				} else {
					data[key] = null
				}
			}
			if (this.props.mode === 'nps') {
				let v = sourceData[key]
				if (v.count_nps_effective !== 0) {
					data[key] = (v.count_nps_promoter - v.count_nps_detractor) * 100 / v.count_nps_effective
				} else {
					data[key] = null
				}
			}
			if (this.props.mode === 'count') {
				data[key] = sourceData[key].count_total
			}
		}

		for (let key of Object.keys(data)) {
			data[key] = roundPolicy(data[key])
		}
		return (
			<div className="GeneralChart">
				<SortedKeysChart
					_key={`${this.props.firstMonthFormatted} ${this.props.lastMonthFormatted} ${this.props.mode}`}
					dataObject={data}
					dataLabel={' '}
					dataColor={'#41bb96'}
					dataPercent={this.props.mode !== 'count'}
					bar={{
						width: {
							ratio: 0.75, // this makes bar width N% of length between ticks
						},
					}}
					legend={{
						hide: true,
					}}
				/>
			</div>
		)
	}
}

GeneralChart.propTypes = {
	mode: PropTypes.string.isRequired, // satisfaction, nps, count
	onChange: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
	return {
		mode: selectMode(state),
		filterSet: selectFilterShort(state),

		firstMonthFormatted: _selectFirstMonthFormatted(state),
		lastMonthFormatted: _selectLastMonthFormatted(state),

		data: selectAggDataKeyedDense(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (newMode) => {
			dispatch(generalModeChanged(newMode))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralChart)
