import { createAction } from 'redux-actions'


// TODO: make global or remove
export const unsafeDirectSet = createAction('UNSAFE_DIRECT_SET', (key, value) => ({ key, value }))

export const filterRegionChanged = createAction('PICKUP_FILTER_REGION_CHANGED', (value) => ({ value }))
export const filterCityChanged = createAction('PICKUP_FILTER_CITY_CHANGED', (value) => ({ value }))
export const filterAreaChanged = createAction('PICKUP_FILTER_AREA_CHANGED', (value) => ({ value }))
export const filterAssemblyTypeChanged = createAction('PICKUP_FILTER_ASSEMBLY_TYPE_CHANGED', (value) => ({ value }))
export const filterExecutorChanged = createAction('PICKUP_FILTER_EXECUTOR_CHANGED', (value) => ({ value }))
export const filterClientTypeChanged = createAction('PICKUP_FILTER_CLIENT_TYPE_CHANGED', (value) => ({ value}))

export const filterDateFirstChanged = createAction('PICKUP_FILTER_DATE_FIRST_CHANGED', (year, month, day) => ({ year, month, day }))
export const filterDateLastChanged = createAction('PICKUP_FILTER_DATE_LAST_CHANGED', (year, month, day) => ({ year, month, day }))
export const filterDateJump = createAction('PICKUP_FILTER_DATE_JUMP', (shortcut) => ({ shortcut }))

export const generalModeChanged = createAction('PICKUP_GENERAL_MODE_CHANGED', (value) => ({ value }))
export const commentKeyChanged = createAction('PICKUP_COMMENT_KEY_CHANGED', (value) => ({ value }))
export const commentTagsKeyChanged = createAction('PICKUP_COMMENT_TAGS_CHANGED', (value) => ({ value }))

export const comparisonColumnAddCurrent = createAction('PICKUP_COMPARISON_COLUMN_ADD_CURRENT', () => ({ /* O_O */ }))
export const comparisonColumnDelete = createAction('PICKUP_COMPARISON_COLUMN_DELETE', (key) => ({ key }))
export const comparisonDataLoaded = createAction('PICKUP_COMPARISON_DATA_LOADED', () => ({ /* O_O */ }))
export const comparisonCriteriaChanged = createAction('PICKUP_COMPARISON_CRITERIA_CHANGED', (value) => ({ value }))

export const ratingCriteriaChanged = createAction('PICKUP_RATING_CRITERIA_CHANGED', (value) => ({ value }))

export const commentKeyLoaded = createAction('PICKUP_COMMEMT_KEY_LOADED', (value) => ({ value }))
