import {
	filterDateFirstChanged, filterDateLastChanged,
	filterAreaChanged,
	generalModeChanged,
	comparisonColumnAddCurrent,
} from './delivery/action'
// import {
// 	selectCompareDisplayFiltersAsKeyTitle,
// 	selectCompareDisplayDataPretty,
// } from '../selector/compare'

import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'


class ComparisonDataProvider extends Component {
	// constructor() {
	// 	super()
	// 	this.handleRowClicked = this.handleRowClicked.bind(this)
	// }

	render() {
		let p = this.props

		return (
			<ul className='nav navbar-nav navbar-right'>
				<li>
					{/* eslint-disable-next-line */}
					<a href='javascript://' className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i className='fa fa-magic' /> {['Колдовство',"Колдунство","Чёрная магия"][Math.floor(Math.abs(Math.random())*3)]} <span className="caret"></span>
					</a>
					<ul className="dropdown-menu">
						<li className="dropdown-header">Доставка</li>
						{/* eslint-disable-next-line */}
						<li><a href='javascript://' onClick={p.deliveryPreset1}>Сравнение 1</a></li>
						{/* eslint-disable-next-line */}
						<li><a href='javascript://' onClick={p.deliveryPreset2}>Отрицательный NPS</a></li>

						<li role="separator" className="divider"></li>

						<li className="dropdown-header">Установка</li>

						<li role="separator" className="divider"></li>

						<li className="dropdown-header">Самовывоз</li>
					</ul>
				</li>
			</ul>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	// let kt = selectCompareDisplayFiltersAsKeyTitle(state)
	// let data = selectCompareDisplayDataPretty(state)

	return {
		// kt: kt.toJS(),
		// data: data.toJS(),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		deliveryPreset1: () => {
			dispatch(filterDateFirstChanged(2019, 3, 1))
			dispatch(filterDateLastChanged(2019, 8, 1))

			dispatch(filterAreaChanged(88))
			dispatch(comparisonColumnAddCurrent())

			dispatch(filterAreaChanged(3))
			dispatch(comparisonColumnAddCurrent())

			dispatch(filterAreaChanged(48))
			dispatch(comparisonColumnAddCurrent())
		},
		deliveryPreset2: () => {
			dispatch(filterAreaChanged(17))
			dispatch(filterDateFirstChanged(2019, 8, 1))
			dispatch(filterDateLastChanged(2019, 8, 1))
			dispatch(generalModeChanged('nps'))
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ComparisonDataProvider)
