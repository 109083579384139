// Простой экспорт в CSV
// Принимает:

// fileName - строка с именем файла (без расширения)

// data - объект, в общем виде содержащий данные построчно в виде массивов, ключи становятся данными для первого столбца, если не передан rows
	// data = {
	// row1column1dataAsKey: [column2data, column3data],
	// row2column1dataAsKey: [column2data, column3data],
	// }
// если данные представлены одним значением, можно передавать как есть:
	// data = {
	// row1column1dataAsKey: column2data,
	// row2column1dataAsKey: column2data,
	// }

// titles - необязательный массив заголовков для первой строки (заголовки можно передавать и непосредственно в data)

// rows - необязательный объект, содержащий данные для первого столбца в литературной форме
	// rows = {
	// row1column1dataAsKey: 'row1column1dataAsString',
	// row2column1dataAsKey: 'row2column1dataAsString',
	// }
// TODO: возможно правильнее было бы использовать ассоциативный массив

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import stringify from 'csv-stringify'

const generate = (fileName, data, titles, rows, charset = 'utf-8') => {
	const output = []
	const stringifier = stringify({
		delimiter: ';',
		cast: {
			number: (value) => ('' + value).replace('.', ',')
		}
	})
	stringifier.on('readable', function () {
		let row;
		// eslint-disable-next-line
		while (row = stringifier.read()) {
			output.push(row)
		}
	})
	stringifier.on('error', function (err) {
		console.error(err.message)
	})
	stringifier.on('finish', () => {
		let ret = output.join('')
		let sourcePromise
		if (charset === 'utf-8') {
			sourcePromise = Promise.resolve(ret)
		} else {
			sourcePromise = import('iconv-lite').then(iconv => {
				return iconv.encode(ret, charset)
			})
		}

		sourcePromise.then(ret => {
			saveAs(
				new Blob(
					[ret],
					{ type: `text/csv;charset=${charset}` },
				),
				fileName + '.csv'
			);
		})
	})

	if (!!titles) {
	stringifier.write(titles)
	}

	let keys = []
	if (!!rows) {
		console.log(rows)
		keys = Object.keys(data).sort((a, b) => {
			return rows[b.key] - rows[a.key]
		})
	} else {
		keys = Object.keys(data).sort()
	}

	let dataContainsArrays = false
	for (let key of keys) {
		if (Array.isArray(data[key])) {
			dataContainsArrays = true
			break
		}
	}
	if (!dataContainsArrays) {
		for (let key of keys) {
			data[key] = [data[key]]
		}
	}

	for (let key of keys) {
		if (!!data[key]) {
			let current = !!rows ? [rows[key]] : [key]
			for (let i = 0; i < data[key].length; ++i) {
				current.push(data[key][i])
			}
			stringifier.write(current)
		}
	}
	stringifier.end()
}


export default class ExportCSV extends Component {
	constructor() {
		super()
		this.handleExportUTF8 = this.handleExportUTF8.bind(this)
		this.handleExport1251 = this.handleExport1251.bind(this)
	}

	handleExportUTF8() {
		return generate(this.props.fileName, this.props.data, this.props.titles ? this.props.titles : null, this.props.rows ? this.props.rows : null)
	}

	handleExport1251() {
		return generate(this.props.fileName, this.props.data, this.props.titles ? this.props.titles : null, this.props.rows ? this.props.rows : null, 'cp1251')
	}

	render() {
		return (
			<div className="btn-group" style={{ marginTop: '0.1em' }}>
				<button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<i className="fa fa-download" /> Сохранить в CSV <span className="caret"></span>
				</button>
				<ul className="dropdown-menu">
					{/* eslint-disable-next-line */}
					<li><a href='javascript://' onClick={this.handleExport1251}><i className="fa fa-list" /> Windows-1251 (Excel)</a></li>
					{/* eslint-disable-next-line */}
					<li><a href='javascript://' onClick={this.handleExportUTF8}><i className="fa fa-list" /> UTF-8</a></li>
				</ul>
			</div>
		)
	}
}

ExportCSV.propTypes = {
	fileName: PropTypes.string.isRequired,
	data: PropTypes.object.isRequired,
	titles: PropTypes.array,
	rows: PropTypes.object,
}
