import { withPath } from '../../selector-util'
import * as source from './filter-local'

const PATH = ['install']
let unwrap = withPath(PATH)

export const _selectFilterRaw = unwrap(source._selectFilterRaw)
export const selectFilterShort = unwrap(source.selectFilterShort)
export const selectFilterFull = unwrap(source.selectFilterFull)
export const _selectFirstMonthFormatted = unwrap(source._selectFirstMonthFormatted)
export const _selectLastMonthFormatted = unwrap(source._selectLastMonthFormatted)
export const selectLimitedMonthsFormatted = unwrap(source.selectLimitedMonthsFormatted)
export const selectTargetGrouping = unwrap(source.selectTargetGrouping)
