import React, { Component } from 'react'
import { connect } from 'react-redux'

import Tabs from '../../generic/Tabs'
import PageHeader from '../../PageHeader'
import GeneralFiltersRoot from './GeneralFiltersRoot'
import MonthFilter from './MonthFilter'
import GeneralChartRoot from './GeneralChartRoot'
import CompareButton from './CompareButton'
import ComparisonRoot from './ComparisonRoot'
import RatingRoot from './RatingRoot'
import CommentsRoot from './CommentsRoot'
import ExportButton from './ExportButton'
import FilterCaption from './FilterCaption'
import RatingCriteriaSwitch from './RatingCriteriaSwitch'
import RatingCheckbox from './RatingCheckbox'
import SecondaryChartsRoot from './SecondaryChartsRoot'
import CovidChartsBlockRoot from './CovidChartsBlockRoot'
import SummaryRoot from './SummaryRoot'
// import CovidFullExportButton from './CovidFullExportButton'
import { selectKnownShops, selectKnownWorkers, selectKnownExecutors, selectKnownTypes, selectDataRange } from '../selector/common-global'


import './Install.css'

const CURRENT_YEAR = new Date().getFullYear()

class Install extends Component {
	render() {
		let p = this.props
		let updatedAt = p.dataRange.lastDay

		return (
			<div className="Install">
				<div className="container">
					<PageHeader
						title="Удовлетворённость по установке и ремонтным работам"
						subtitle={`Последнее обновление: ${updatedAt.day}.${updatedAt.month}.${updatedAt.year}`}
					/>
					<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
						<div className="col-sm-8" style={{ paddingLeft: '0em', paddingRight: '0em' }}>
							<GeneralFiltersRoot />
						</div>
						<div className="col-sm-4 text-right" style={{ paddingRight: '0em' }}>
							<MonthFilter />
						</div>
						<div className="col-sm-12" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
							<CompareButton />
						</div>
						<div className="col-sm-12">
							<FilterCaption />
						</div>
					</div>

					<Tabs
						tabs={{
							'Основная статистика': (
								<div>
									<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
										<SummaryRoot />
									</div>

									<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
										<div className="col-md-12">
											<h3>Основная статистика</h3>
										</div>
									</div>
									<div className="row" style={{ marginTop: '1.5em' }}>
										<GeneralChartRoot />
									</div>
								</div>
							),
							'По критериям': (
								<div className="row">
									<SecondaryChartsRoot />
								</div>
							),
							'Сравнение': (
								<div>
									<h3>Сравнение</h3>
									<div className="row" style={{ marginTop: '1.5em' }}>
										<ComparisonRoot />
									</div>
								</div>
							),
							'СИЗ': (
								<div>
									<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
										<div className="col-md-12">
											{/* <div className="pull-right"><ExportButton
												covidOnly={true}
											/></div> */}
											<h3>Средства индивидуальной защиты</h3>
										</div>
									</div>
									<div className="row" style={{ marginTop: '0.5em' }}>
										<CovidChartsBlockRoot />
									</div>
								</div>
							),
							'Комментарии': (
								<div>
									<div style={{ backgroundColor: '#f0fbf7', marginLeft: '-2em', paddingLeft: '2em', marginRight: '-2em', paddingRight: '2em', marginTop: '1.5em' }}>
										<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
											<div className="col-md-12">
												<div className="pull-right"><ExportButton /></div>
												<h3>Комментарии клиентов</h3>
												<div className="text-left text-muted"><small>Выберите категорию, чтобы увидеть полный список комментариев</small></div>
											</div>
										</div>
										<div className="row" style={{ marginTop: '1.5em' }}>
											<CommentsRoot />
										</div>
									</div>
								</div>
							),
							'Рейтинг': (
								<div>
									<div className="row">
										<div className="col-md-12">
											<h3>Рейтинг</h3>
										</div>
									</div>
									<div className="row" style={{ marginTop: '0.5em' }}>
										<div className="col-md-8 col-sm-12" style={{ paddingTop: '0.5em' }}>
											<RatingCheckbox />
										</div>
										<div className="col-md-4 col-sm-12 text-md-right text-xs-left" style={{ paddingTop: '0.5em' }}>
											<RatingCriteriaSwitch />
										</div>
									</div>
									<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
										<div className="col-md-12 rating">
											<RatingRoot />
										</div>
									</div>
								</div>
							),
						}}
						order={['Основная статистика', 'По критериям', 'Сравнение', 'СИЗ', 'Комментарии', 'Рейтинг']}
						default='Основная статистика'
					/>

					<div className="row" style={{ marginTop: '0.5em', paddingTop: '0.5em' }}>
						<div className="col-md-12">
							<hr />
							<p className="text-center text-muted">&copy; <a href="http://www.comsystems-russia.com/">Комсистемс</a>, 2018...{CURRENT_YEAR}</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		dataRange: selectDataRange(state).toJS(),
		knownShops: selectKnownShops(state).toJS(),
		knownWorkers: selectKnownWorkers(state).toJS(),
		knownExecutors: selectKnownExecutors(state).toJS(),
		knownTypes: selectKnownTypes(state).toJS(),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Install)
