import { roundPolicy } from '../../selector-util'

import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import ReactTable from 'react-table'; import 'react-table/react-table.css'
import QuestionIcon from '../../generic/QuestionIcon'


const formatInt = (x, sep = ' ', ifZero = '0') => {
	if (x === null) return '—'
	if (x === 0) return ifZero

	// Don't attempt to process floats
	if (x - Math.floor(x) !== 0) {
		return '' + x
	}

	let s = '' + x
	let ret = []
	while (s.length > 3) {
		ret.push(s.substring(s.length - 3))
		s = s.substring(0, s.length - 3)
	}
	ret.push(s)
	ret.reverse()
	return ret.join(sep)
}


const makeDiffBlock = (value, refValue) => {
	let ret = null

	let diff = roundPolicy(value - refValue)
	if (diff > 0) ret = (
		<small className="text-success">+{Math.abs(diff)}</small>
	)
	if (diff < 0) ret = (
		<small className="text-danger">&minus;{Math.abs(diff)}</small>
	)

	return ret
}


export default class ComparisonTable extends Component {
	// constructor() {
	// 	super()
	// 	this.handleRowClicked = this.handleRowClicked.bind(this)
	// }

	// handleRowClicked(key, event) {
	// 	this.props.onDetailedKeyChange(key)
	// }

	render() {
		let p = this.props

		let referenceRow = p.data.filter((x) => x.tag === 'target')[0]

		let columns = [{
			Header: '',
			accessor: 'title',
			Cell: (props) => {
				// return <pre>{JSON.stringify(props, null, 2)}</pre>

				let caption = (<span>{props.value}</span>)
				if (props.original.tag !== 'data') {
					caption = (<span><b>{props.value}</b></span>)
				}

				let cross = (
					<span>
						{/* eslint-disable-next-line */}
						<a
							// eslint-disable-next-line
							href="javascript://"
							onClick={() => p.onDelete({ key: props.value })}
							style={{ visibility: (props.original.tag !== 'data' ? 'hidden' : null) }}
						>
							<i className="fa fa-times" />
						</a>
					</span>
				)

				return (
					<span>{cross}{' '}{caption}</span>
				)
			},
		}]

		for (let col of p.columns) {
			let cd = {
				Header: (col.title === 'CSI (по мастерам)' || col.title === 'CSI (в целом)' || col.title === 'NPS' || col.title === 'Детали NPS' ? <QuestionIcon title={col.title} /> : col.title),
				accessor: `values.${col.key}`,
				Cell: (props) => {
					if (col.extension === 'nps-details') {
						let val = props.value
						if (!val) return [(<div className="pull-right">—</div>)]
						return [(
							<div className="progress">
								<div
									className="progress-bar progress-bar-success"
									role="progressbar"
									aria-valuenow={val.promoter}
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: `${val.promoter}%` }}
								>{Math.round(val.promoter)}%</div>
								<div
									className="progress-bar"
									role="progressbar"
									aria-valuenow={val.neutral}
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: `${val.neutral}%`, backgroundImage: 'linear-gradient(to bottom,#b3b3b3 0,#b3b3b3 100%)' }}
								>{Math.round(val.neutral)}%</div>
								<div
									className="progress-bar progress-bar-danger"
									role="progressbar"
									aria-valuenow={val.detractor}
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: `${val.detractor}%` }}
								>{Math.round(val.detractor)}%</div>
							</div>
						)]
					}
					if (col.extension === 'diff') {
						let val = props.value
						let diffBlock = makeDiffBlock(val, referenceRow.values[col.key])
						return [(
							<div className="progress" key={1}>
								<div
									className="progress-bar progress-bar-success"
									role="progressbar"
									aria-valuenow={Math.round(val)}
									aria-valuemin="0"
									aria-valuemax="100"
									style={{ width: `${Math.round(val)}%` }}
								/>
							</div>
						), (<span key={2}>{val}% &nbsp; {diffBlock}</span>)]
					}

					return (<div className="pull-right">{formatInt(props.value, ' ', '—')}</div>)
				}
			}
			if (col.maxWidthPx != null) {
				cd.maxWidth = col.maxWidthPx
			}
			if (!col.exportOnly) {
				columns.push(cd)
			}
		}

		return (
			<div className="ComparisonTable">
				<ReactTable
					data={p.data}
					columns={columns}
					showPagination={false}
					minRows={0}
					sortable={false}
					resizable={false}
					defaultPageSize={100}
				/>
			</div>
		)
	}
}
