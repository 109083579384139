import { formatDay, monthSequence, roundPolicy } from '../../selector-util'
import { selectTargetGrouping, _selectFilterRaw } from './filter-local'

import { createSelector } from 'reselect'
import { fromJS } from 'immutable'
import moment from 'moment'

const or = (x, value = null) => {
	if (x == null) return value
	return x
}

export const selectMode = (state) => state.getIn(['general', 'mode'])

export const selectAggData = (state) => state.getIn(['general', 'data', 'data']).get(0)

export const selectAggDataKeyed = createSelector(
	selectAggData,
	(data) => {
		let ret = fromJS({})
		for (let curr of data) {
			ret = ret.set(curr.get('when'), curr)
		}
		return ret
	}
)

export const selectAggDataKeyedDense = createSelector(
	selectAggDataKeyed,
	selectTargetGrouping,
	_selectFilterRaw,
	(imap, mode, filterDate) => {
		const empty = (key) => ({
			"count_nps_detractor": 0,
			"count_nps_effective": 0,
			"count_nps_promoter": 0,
			"count_satisfaction_effective": 0,
			"count_satisfaction_yes": 0,
			"count_total": null,
			"when": key,
		})

		if (mode === 'day') {
			let y = filterDate.getIn(['firstMonth', 'year'])
			let m = filterDate.getIn(['firstMonth', 'month'])

			let daysInThisMonth = moment.utc({ year: y, month: m - 1 }).daysInMonth()

			for (let d = 1; d <= daysInThisMonth; d++) {
				let key = formatDay(y, m, d)
				imap = imap.update(key, (x) => x || empty(key))
			}
		} else {
			let y1 = filterDate.getIn(['firstMonth', 'year'])
			let m1 = filterDate.getIn(['firstMonth', 'month'])
			let y2 = filterDate.getIn(['lastMonth', 'year'])
			let m2 = filterDate.getIn(['lastMonth', 'month'])

			// console.log(`monthSequence for selectSatisfactionDataKeyedDense`)
			for (let key of monthSequence(m1, y1, m2, y2)) {
				imap = imap.update(key, (x) => x || empty(key))
			}
		}
		return imap
	}
)

export const selectCovidDataKeyed = createSelector(
	selectAggData,
	(idata) => {
		// TODO: probably can be done with immutables only
		let data = idata.toJS()
		let ret = {}
		for (let x of data) {
			let countTotal = x.count_covid_effective - x.count_covid_equipment_ineffective
			let countDoNotKnow = countTotal - (x.count_covid_full_equipment + x.count_covid_mask_only + x.count_covid_gloves_only + x.count_covid_no_equipment)
			if (countTotal === 0) {
				ret[x.when] = null
			} else {
				ret[x.when] = [
					roundPolicy(countDoNotKnow * 100 / countTotal),
					roundPolicy(x.count_covid_no_equipment * 100 / countTotal),
					roundPolicy(x.count_covid_gloves_only * 100 / countTotal),
					roundPolicy(x.count_covid_mask_only * 100 / countTotal),
					roundPolicy(x.count_covid_full_equipment * 100 / countTotal),
				]
			}
		}
		return fromJS(ret)
	}
)

export const selectCovidDataKeyedDense = createSelector(
	selectCovidDataKeyed,
	selectTargetGrouping,
	_selectFilterRaw,
	(imap, mode, filterDate) => {

		if (mode === 'day') {
			let y = filterDate.getIn(['firstMonth', 'year'])
			let m = filterDate.getIn(['firstMonth', 'month'])

			let daysInThisMonth = moment.utc({ year: y, month: m - 1 }).daysInMonth()

			for (let d = 1; d <= daysInThisMonth; d++) {
				imap = imap.update(formatDay(y, m, d), (x) => or(x, null))
			}
		} else {
			let y1 = filterDate.getIn(['firstMonth', 'year'])
			let m1 = filterDate.getIn(['firstMonth', 'month'])
			let y2 = filterDate.getIn(['lastMonth', 'year'])
			let m2 = filterDate.getIn(['lastMonth', 'month'])

			for (let key of monthSequence(m1, y1, m2, y2)) {
				//null used to avoid zero values on chart for empty data (previously was 0)
				imap = imap.update(key, (x) => or(x, null))
			}
		}

		return imap
	}
)

export const selectCovidDataAgg = createSelector(
	selectAggData,
	(idata) => {
		let data = idata.toJS()
		let ret = {
			countFullEquipment: null,
			countMaskOnly: null,
			countGlovesOnly: null,
			countNoEquipment: null,
			countDoNotKnow: null
		}
		for (let x of data) {
			ret.countFullEquipment = ret.countFullEquipment + x.count_covid_full_equipment
			ret.countMaskOnly = ret.countMaskOnly + x.count_covid_mask_only
			ret.countGlovesOnly = ret.countGlovesOnly + x.count_covid_gloves_only
			ret.countNoEquipment = ret.countNoEquipment + x.count_covid_no_equipment
			ret.countDoNotKnow = ret.countDoNotKnow + x.count_covid_effective - (x.count_covid_equipment_ineffective + x.count_covid_full_equipment + x.count_covid_mask_only + x.count_covid_gloves_only + x.count_covid_no_equipment)
		}
		return fromJS(ret)
	}
)

export const selectCovidDistanceData = createSelector(
	selectAggData,
	selectCovidDataAgg,
	(idata, aggdata) => {
		let data = idata.toJS()
		let countTotal = Object.values(aggdata.toJS()).reduce((a, b) => a + b)

		// [да, затрудняюсь ответить, нет]
		let ret = { covidDistance: [null, null, null] }
		for (let x of data) {
			ret.covidDistance[0] = ret.covidDistance[0] + x.count_covid_distance_yes
			ret.covidDistance[2] = ret.covidDistance[2] + x.count_covid_distance_no
		}
		ret.covidDistance[1] = countTotal - ret.covidDistance[0] - ret.covidDistance[2]
		ret.covidDistance = ret.covidDistance.map(e => roundPolicy(e * 100 / countTotal))
		return fromJS(ret)
	}
)
