import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { filterDateFirstChanged, filterDateLastChanged, filterDateJump } from '../action'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'
import MonthRange from '../../generic/MonthRange'


class MonthFilter extends Component {
	render() {
		return (
			<div className="MonthFilter" style={{ 'display': 'inline-block' }}>
				<div>
					<div className="btn-group">
						<button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i className="far fa-calendar-alt" />{' '}Период <span className="caret"></span>
						</button>
						<ul className="dropdown-menu">
							{/* eslint-disable-next-line */}
							<li><a onClick={() => this.props.onJump('this-month')} href='javascript://'>Текущий месяц</a></li>
							{/* eslint-disable-next-line */}
							<li><a onClick={() => this.props.onJump('prev-month')} href='javascript://'>Прошлый месяц</a></li>
							<li role="separator" className="divider"></li>
							{/* eslint-disable-next-line */}
							<li><a onClick={() => this.props.onJump('this-quarter')} href='javascript://'>Текущий квартал</a></li>
							{/* eslint-disable-next-line */}
							<li><a onClick={() => this.props.onJump('prev-quarter')} href='javascript://'>Прошлый квартал</a></li>
							<li role="separator" className="divider"></li>
							{/* eslint-disable-next-line */}
							<li><a onClick={() => this.props.onJump('ytd')} href='javascript://'>С начала года</a></li>
							{/* eslint-disable-next-line */}
							<li><a onClick={() => this.props.onJump('last-12-months')} href='javascript://'>Последние 12 месяцев</a></li>
						</ul>
					</div>
					{' '}
					<MonthRange
						valueFirstMonth={this.props.valueFirstMonth}
						valueLastMonth={this.props.valueLastMonth}
						onChangeFirst={this.props.onChangeFirst}
						onChangeLast={this.props.onChangeLast}
					/>
				</div>
			</div>
		)
	}
}

MonthFilter.propTypes = {
	valueFirstMonth: PropTypes.string.isRequired,
	valueLastMonth: PropTypes.string.isRequired,
	onChangeFirst: PropTypes.func,
	onChangeLast: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
	return {
		valueFirstMonth: _selectFirstMonthFormatted(state),
		valueLastMonth: _selectLastMonthFormatted(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChangeFirst: (value) => {
			let y, m
			[y, m] = value.split('-') // MonthRange guaratees it to be valid
			y = +y
			m = +m
			dispatch(filterDateFirstChanged(y, m, 1))
		},
		onChangeLast: (value) => {
			let y, m
			[y, m] = value.split('-') // MonthRange guaratees it to be valid
			y = +y
			m = +m
			dispatch(filterDateLastChanged(y, m, 1))
		},

		onJump: (value) => {
			dispatch(filterDateJump(value))
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MonthFilter)
