import React, { Component } from 'react'
import { connect } from 'react-redux'
import HorizontalBarChartCJ from '../../generic/HorizontalBarChartCJ'
import PieChartCJ from '../../generic/PieChart'

const CHART_TITLES = {
	'rating_ease':'Простота оформления заказа',
	'rating_price':'Удовлетворённость ценой работ',
	'rating_quality':'Качество работы',
//	'rating_tidy':'Порядок и чистота после работ',
	'rating_timing':'Пунктуальность выполнения работ',
}
const PIE_TITLES = [
	'Были доплаты за рамками договора',
	'Доплат не потребовалось'
]

class SecondaryCharts extends Component {
	render() {
		let sourceData = this.props.data.toJS()[0][0]
		let chartData = {}
		let pieData = {}

		if (typeof sourceData !== 'undefined') {

			for (let key of Object.keys(CHART_TITLES)) {
				if (sourceData['count_' + key] !== 0) {
					chartData[CHART_TITLES[key]] = sourceData['sum_' + key] / sourceData['count_' + key]
				} else {
					chartData[CHART_TITLES[key]] = null
				}
			}

			if (sourceData['count_total'] !== 0) {
				pieData = { 'yes': sourceData['count_additional_payment_yes'] * 100 / sourceData['count_total'], 'no': (1 - sourceData['count_additional_payment_yes'] / sourceData['count_total']) * 100 }
			}

		}

		return (
			<div className="SecondaryCharts">
				<div className="col-md-8" style={{paddingLeft:0}}>
					<h3>Критерии оценки качества выполненных работ</h3>
					<div className="SortedHorizontalBarChartCJ">
						<HorizontalBarChartCJ
							dataObject={chartData}
							dataLabel={' '}
							dataColor={'#41bb96'}
							dataPercent={false}
							decimalPlaces={1}
							bar={{
								width: {
									ratio: 0.75,
								},
							}}
							legend={{
								hide: true,
							}}
						/>
					</div>
				</div>
				<div className="col-md-4" style={{paddingLeft:0}}>
					<h3>Статистика доплат</h3>
					<div className="PieChartCJ">
						<PieChartCJ
							dataObject={pieData}
							dataLabel={PIE_TITLES}
							dataColor={['#eb5454', '#41bb96']}
							dataPercent={true}
							decimalPlaces={0}
						/>
					</div>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SecondaryCharts)
