import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { URL } from 'url-polyfill/do-not-use/url'

import { API_PREFIX } from '../../config'
import { selectFilterShort, _selectFirstMonthFormatted, _selectLastMonthFormatted } from '../selector/filter-global'


class ExportButton extends Component {
	// constructor() {
	// 	super()
	// 	this.handleRowClicked = this.handleRowClicked.bind(this)
	// }

	// handleRowClicked(key, event) {
	// 	this.props.onDetailedKeyChange(key)
	// }

	render() {
		let p = this.props
		let fs = p.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type'])

		let getExportURL = (charset) => {
			let url = new URL(`${API_PREFIX}/install/install/query/`)
			if (fs.region) url.searchParams.set('region', fs.region)
			if (fs.city) url.searchParams.set('city', fs.city)
			if (fs.area) url.searchParams.set('area', fs.area)
			if (fs.executor) url.searchParams.set('executor', fs.executor)
			if (fs.worker) url.searchParams.set('worker', fs.worker)
			if (fs.type) url.searchParams.set('what', fs.type)
			if (p.firstMonthFormatted) url.searchParams.set('month_from', p.firstMonthFormatted)
			if (p.lastMonthFormatted) url.searchParams.set('month_to', p.lastMonthFormatted)
			// url.searchParams.set('require_filled', key)
			url.searchParams.set('format', 'csv')
			url.searchParams.set('charset', charset)
			url = url.pathname + url.search
			return url
		}

		return (
			<div className="ExportButton">
				<div className="btn-group">
					<button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i className="fa fa-download" /> Выгрузка в CSV <span className="caret"></span>
					</button>
					<ul className="dropdown-menu">
						<li><a href={getExportURL('cp1251')}><i className="fa fa-list" /> Windows-1251 (Excel)</a></li>
						<li><a href={getExportURL('utf-8')}><i className="fa fa-list" /> UTF-8</a></li>
					</ul>
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		filterSet: selectFilterShort(state),

		firstMonthFormatted: _selectFirstMonthFormatted(state),
		lastMonthFormatted: _selectLastMonthFormatted(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ExportButton)
