import ComparisonChartDataProvider from './ComparisonChartDataProvider'
import ComparisonChart from './ComparisonChart'

import ComparisonDataProvider from './ComparisonDataProvider'
import ComparisonTable from './ComparisonTable'
import ComparisonCSVExport from './ComparisonCSVExport'

import React, { Component } from 'react'
// import PropTypes from 'prop-types'


export default class ComparisonBlock extends Component {
	render() {
		return (
			<div className="ComparisonBlock">
				<div>
					<ComparisonChartDataProvider
						renderer={ComparisonChart}
					/>
				</div>
				<div>
					<ComparisonDataProvider
						renderer={ComparisonTable}
					/>
				</div>
				<div>
					<ComparisonDataProvider
						renderer={ComparisonCSVExport}
					/>
				</div>
			</div>
		)
	}
}
