import { createSelector } from 'reselect'
import { fromJS } from 'immutable'

import { roundPolicy } from '../../selector-util'

export const selectCompareNextFilters = (state) => state.getIn(['compare', 'nextFilters'])

export const selectCompareDisplayFilters = (state) => state.getIn(['compare', 'displayFilters'])

export const selectComparisonCriteria = (state) => state.getIn(['compare', 'criteria'])

export const selectCompareDisplayData = (state) => {
	let ret = state.getIn(['compare', 'displayData', 'data'])
	if (!!ret) return ret.get(0)
	return null
}

export const selectCompareNextDataIsLoading = (state) => state.getIn(['compare', 'nextData', 'loading'])

export const selectCompareDisplayFiltersAsKeyTitle = createSelector(
	selectCompareDisplayFilters,
	(filters) => filters.map(f => ({
		key: f.get('columnKey'),
		title: f.get('columnKey'),
	})),
)

export const selectCompareDisplayDataPretty = createSelector(
	selectCompareDisplayFilters,
	selectCompareDisplayData,
	(filters, data) => {
		let ret = {}
		for (let i = 0; i < filters.size; i++) {
			let key = filters.get(i).get('columnKey')
			let count = 0
			let count_satisfaction_yes = 0
			let count_satisfaction_effective = 0
			let count_promoter = 0
			let count_detractor = 0
			let count_nps_effective = 0
			let count_worker_yes = 0
			let count_worker_effective = 0

			let countData = data.get(i)
			for (let section of countData) {
				count += section.get('count_total')
				count_satisfaction_yes += section.get('count_satisfaction_yes')
				count_satisfaction_effective += section.get('count_satisfaction_effective')
				count_promoter += section.get('count_nps_promoter')
				count_detractor += section.get('count_nps_detractor')
				count_nps_effective += section.get('count_nps_effective')
				count_worker_yes += section.get('count_worker_yes')
				count_worker_effective += section.get('count_worker_effective')
			}

			let csi = roundPolicy(count_satisfaction_yes * 100 / count_satisfaction_effective)
			let csi_worker = roundPolicy(count_worker_yes * 100 / count_worker_effective)
			let nps = roundPolicy((count_promoter - count_detractor) * 100 / count_nps_effective)

			let count_neutral = count_nps_effective - count_promoter - count_detractor
			let count_satisfaction_no = count_satisfaction_effective - count_satisfaction_yes
			let count_worker_no = count_worker_effective - count_worker_yes

			ret[key] = {
				count, csi, csi_worker, nps, count_satisfaction_yes, count_satisfaction_effective, count_promoter, count_detractor, count_nps_effective, count_neutral, count_satisfaction_no, count_worker_yes, count_worker_no
			}
		}
		return fromJS(ret)
	}
)

export const selectCompareDisplayChartDataPretty = createSelector(
	selectCompareDisplayFilters,
	selectCompareDisplayData,
	(filters, data) => {
		let ret = {}
		let comparisonLimit = 5
		for (let i = 1; i < (filters.size > comparisonLimit + 1 ? comparisonLimit + 1 : filters.size); i++) {
			let key = filters.get(i).get('columnKey')
			let countData = data.get(i)
			let indexes = {}
			for (let section of countData) {
				let satisfaction = (section.get('count_satisfaction_effective') !== 0) ? Math.round(section.get('count_satisfaction_yes') * 100 * 10 / section.get('count_satisfaction_effective')) / 10 : null
				let satisfaction_worker = (section.get('count_worker_effective') !== 0) ? Math.round(section.get('count_worker_yes') * 100 * 10 / section.get('count_worker_effective')) / 10 : null
				let nps = (section.get('count_nps_effective') !== 0) ? Math.round((section.get('count_nps_promoter') - section.get('count_nps_detractor')) * 100 * 10 / section.get('count_nps_effective')) / 10 : null
				indexes[section.get('when')] = { satisfaction, satisfaction_worker, nps }
			}

			ret[key] = indexes
		}
		return fromJS(ret)
	}
)
