import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { INSTALL_MODES } from '../../config'
import { generalModeChanged } from '../action'
import { loadAgg } from '../asyncAction'
import { selectFilterFull } from '../selector/filter-global'
import { selectMode } from '../selector/general-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import GeneralChart from './GeneralChart'


const PATH = ['install', 'general', 'data']
const SOURCES = [ loadAgg() ]

class GeneralChartRoot extends Component {
	render() {
		let p = this.props
		let fs = p.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

		return (
			<div className="GeneralChartRoot">
				<div className="col-md-3">
					<ul className="nav nav-pills nav-stacked">
						{INSTALL_MODES.map((x) => (
							<li
								key={x.key}
								role="presentation"
								className={this.props.mode === x.key ? 'active' : ''}
								onClick={() => this.props.onChange(x.key)}
							>
								{/* eslint-disable-next-line */}
								<a href="javascript://">{x.title}</a>
							</li>
						))}
					</ul>
				</div>
				<div className="col-md-9">
					<ErrorBoundary>
						<DataProvider
							dataKey={JSON.stringify(fs)}
							path={PATH}
							sources={SOURCES}
							component={GeneralChart}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}

GeneralChartRoot.propTypes = {
	mode: PropTypes.string.isRequired, // csi, csi_worker, nps, count
	onChange: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
	return {
		mode: selectMode(state),
		filterSet: selectFilterFull(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (newMode) => {
			dispatch(generalModeChanged(newMode))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralChartRoot)
