import { API_PREFIX } from '../config'
import { ShortFilterSet } from './filter'
import { selectCommentDetailedKeyAsField } from './selector/comment-global'
import {
	_selectFirstMonthFormatted, _selectLastMonthFormatted,
	selectFilterFull, selectTargetGrouping,
} from './selector/filter-global'
import { selectCompareNextFilters } from './selector/compare-global'

import { fromJS } from 'immutable'
import { URL } from 'url-polyfill/do-not-use/url'


const handleResponse = (response, json = true) => {
	if (response.ok) {
		if (json) {
			return response.json()
		} else {
			return response
		}
	} else {
		throw response
	}
}

function getCookie(name) {
	var cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		var cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

// eslint-disable-next-line
const getCSRFToken = () => {
	return getCookie('csrftoken')
}

// export const loadList = () => {
// 	return (dispatch, getState) => {
// 		dispatch(action.toplevelLoading())
//
// 		let url = `${config.API_PREFIX}/survey/list?limit=9001` // TODO: pagination
// 		fetch(url, {
// 			method: 'GET',
// 			credentials: 'include',
// 		}).then((response) => {
// 			return handleResponse(response)
// 		}).then((response) => {
// 			dispatch(action.listUpdated(response))
// 			dispatch(action.toplevelLoadingDone())
// 		}).catch((response) => {
// 			console.error(response)
// 			dispatch(action.toplevelLoadingError(response))
// 		})
// 	}
// }


export const loadAgg = (group_by) => (dispatch, getState) => {
	let state = getState()

	let fs = selectFilterFull(state).unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

	let region = fs.region
	let city = fs.city
	let area = fs.area
	let executor = fs.executor
	let worker = fs.worker
	let type = fs.type
	let firstMonthFormatted = _selectFirstMonthFormatted(state)
	let lastMonthFormatted = _selectLastMonthFormatted(state)

	let g = group_by
	if (!g) {
		g = selectTargetGrouping(state)
	}

	let url = new URL(`${API_PREFIX}/install/install-agg/stats/`)

	if (region) url.searchParams.set('region', region)
	if (city) url.searchParams.set('city', city)
	if (area) url.searchParams.set('area', area)
	if (executor) url.searchParams.set('executor', executor)
	if (worker) url.searchParams.set('worker', worker)
	if (type) url.searchParams.set('what', type)
	if (firstMonthFormatted) url.searchParams.set('month_from', firstMonthFormatted)
	if (lastMonthFormatted) url.searchParams.set('month_to', lastMonthFormatted)
	url.searchParams.set('group_by', g)
	url = url.pathname + url.search

	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadRaw = (currentCategoryOnly=true) => (dispatch, getState) => {
	let state = getState()

	let fs = selectFilterFull(state).unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

	let region = fs.region
	let city = fs.city
	let area = fs.area
	let executor = fs.executor
	let worker = fs.worker
	let type = fs.type
	let firstMonthFormatted = _selectFirstMonthFormatted(state)
	let lastMonthFormatted = _selectLastMonthFormatted(state)
	let key = selectCommentDetailedKeyAsField(state)

	let url = new URL(`${API_PREFIX}/install/install/query/`)
	if (region) url.searchParams.set('region', region)
	if (city) url.searchParams.set('city', city)
	if (area) url.searchParams.set('area', area)
	if (executor) url.searchParams.set('executor', executor)
	if (worker) url.searchParams.set('worker', worker)
	if (type) url.searchParams.set('what', type)
	if (firstMonthFormatted) url.searchParams.set('month_from', firstMonthFormatted)
	if (lastMonthFormatted) url.searchParams.set('month_to', lastMonthFormatted)
	if (currentCategoryOnly) url.searchParams.set('with_comment', key)
	url = url.pathname + url.search

	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadKnownShops = (dispatch, getState) => {
	let url = new URL(`${API_PREFIX}/install/shops/`)
	url = url.pathname + url.search

	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadDataRange = (dispatch, getState) => {
	let url = new URL(`${API_PREFIX}/install/install-agg/data_range/`)
	url = url.pathname + url.search

	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadFilterTuplesRaw = (dispatch, getState) => {
	let state = getState()

	let firstMonthFormatted = _selectFirstMonthFormatted(state)
	let lastMonthFormatted = _selectLastMonthFormatted(state)

	let url = new URL(`${API_PREFIX}/install/install-agg/agg_filter_data/`)
	if (firstMonthFormatted) url.searchParams.set('month_from', firstMonthFormatted)
	if (lastMonthFormatted) url.searchParams.set('month_to', lastMonthFormatted)
	url = url.pathname + url.search

	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadSpecificField = (targetField) => (dispatch, getState) => {
	let url = new URL(`${API_PREFIX}/install/install-agg/field_values/`)

	url.searchParams.set('field', targetField)
	url = url.pathname + url.search

	return fetch(url, {
		method: 'GET',
		credentials: 'include',
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadComparisonData = (dispatch, getState) => {
	let state = getState()

	let firstMonthFormatted = _selectFirstMonthFormatted(state)
	let lastMonthFormatted = _selectLastMonthFormatted(state)
	let filterspecs = selectCompareNextFilters(state)
	let group_by = 'month' // selectTargetGrouping(state)

	let url = new URL(`${API_PREFIX}/install/combined/`)

	let payload = []
	for (let spec of filterspecs) {
		let fs = (
			ShortFilterSet
			.create(spec.get('filter').toJS())
			.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type'])
		)

		payload.push(fromJS({
			"filter": {
				"month_from": firstMonthFormatted,
				"month_to": lastMonthFormatted,
				"region": fs.region || null,
				"city": fs.city || null,
				"area": fs.area || null,
				"executor": fs.executor || null,
				"worker": fs.worker || null,
				"what": fs.type || null,
			},
			"group_by": group_by,
		}))
	}

	url = url.pathname + url.search // drop type
	return fetch(url, {
		method: 'POST',
		credentials: 'include',

		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => {
		return handleResponse(response, true)
	})
}

export const loadSummaryData = (dispatch, getState) => {
	let state = getState()

	let fs = selectFilterFull(state).unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

	let firstMonthFormatted = _selectFirstMonthFormatted(state)
	let lastMonthFormatted = _selectLastMonthFormatted(state)
	let group_by = 'month' // selectTargetGrouping(state)

	let url = new URL(`${API_PREFIX}/install/combined/`)

	let payload = []

	payload.push(fromJS({
		"filter": {
			"month_from": firstMonthFormatted,
			"month_to": lastMonthFormatted,
			"region": fs.region || null,
			"city": fs.city || null,
			"area": fs.area || null,
			"executor": fs.executor || null,
			"worker": fs.worker || null,
			"what": fs.type || null,
		},
		"group_by": group_by,
	}))

	url = url.pathname + url.search // drop type
	return fetch(url, {
		method: 'POST',
		credentials: 'include',

		body: JSON.stringify(payload),
		headers: {
			'Content-Type': 'application/json'
		}
	}).then((response) => {
		return handleResponse(response, true)
	})
}
