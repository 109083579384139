import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

// BUG: nullOption actually means "", not `null`. It was made this way
// because of react-select issues, but we want to work them around eventually.

const colors = {
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: ((data.value == null) || (data.value === '')) ? '#888' : null,
			backgroundColor: (
				isDisabled
					? null
					: isSelected
						? '#9ae1cc'
						: isFocused
							? '#9ae1cc'
							: null
			),
		}
	},
	singleValue: (styles, { data, isDisabled, isFocused, isSelected }) => {
		return {
			...styles,
			color: ((data.value == null) || (data.value === '')) ? '#888' : null,
		}
	},
}

export default class PleaseSelectSearchable extends Component {
	constructor() {
		super()
		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(event) {
		return this.props.onChange(event.value, event)
	}

	render() {
		// Sanity check
		for (let item of this.props.data) {
			if (item.id == null) {
				throw new Error('PleaseSelectSearchable: null id is not allowed')
			}
		}

		let options = []
		if (this.props.nullOption) {
			options.push({ value: null, label: this.props.nullOption })
		}
		for (let item of this.props.data) {
			options.push({ value: item.id, label: item.title })
		}

		let currentOption = null
		for (let option of options) {
			if (this.props.value === option.value) currentOption = option
		}

		return (
			<Select
				value={currentOption}
				onChange={this.handleChange}
				options={options}
				styles={colors}
				isSearchable={true}
				isClearable={false}  // TODO: hmmm!
			/>
		)
	}
}

PleaseSelectSearchable.propTypes = {
	data: PropTypes.array.isRequired,
	nullOption: PropTypes.string,
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
}
