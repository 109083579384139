import React, { Component } from 'react'
import { connect } from 'react-redux'

import { loadFilterTuplesRaw } from '../asyncAction'
import { selectFilterFull } from '../selector/filter-global'
import ErrorBoundary from '../../generic/ErrorBoundary'
import DataProvider from '../../generic/DataProvider'
import GeneralFilters from './GeneralFilters'

const PATH = ['install', 'generalFilterTuples', 'data']
const SOURCES = [ loadFilterTuplesRaw ]

class GeneralFiltersRoot extends Component {
	render() {
		let p = this.props
		let fs = p.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type', 'firstMonth', 'lastMonth'])

		return (
			<div className="GeneralFiltersRoot">
				<div className="col-md-12">
					<ErrorBoundary>
						<DataProvider
							dataKey={JSON.stringify(fs)}
							path={PATH}
							sources={SOURCES}
							component={GeneralFilters}
						/>
					</ErrorBoundary>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		filterSet: selectFilterFull(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralFiltersRoot)
