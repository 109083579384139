import logo from './logo.png'

import React from 'react'
// import { Component } from 'react'

import './PageHeader.css'


// eslint-disable-next-line
export default (props) => (
	<div className="PageHeader">
		<div className="row">
			<div className="col-md-12">
				<table>
				<tbody>
				<tr>
					<td className="logo-container">
						<img className="logo" src={logo} alt="Leroy Merlin" />
					</td>
					<td className="title-container">
						<h1>{props.title}</h1>
						<p className="text-muted">{props.subtitle}</p>
					</td>
				</tr>
				</tbody>
				</table>
			</div>
		</div>
	</div>
)
