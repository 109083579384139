import { pad } from '../../selector-util'
import { FullFilterSet, ShortFilterSet } from '../filter'

import { createSelector } from 'reselect'


export const _selectFilterRaw = (state) => state.getIn(['filter'])

export const selectFilterShort = createSelector(
	_selectFilterRaw,
	(raw) => ShortFilterSet.create(raw.delete('firstMonth').delete('lastMonth').toJS()),
)

export const selectFilterFull = createSelector(
	_selectFilterRaw,
	(raw) => FullFilterSet.create(raw.toJS()),
)

export const _selectFirstMonthFormatted = createSelector(
	_selectFilterRaw,
	(raw) => {
		let y = raw.getIn(['firstMonth', 'year'])
		let m = raw.getIn(['firstMonth', 'month'])
		return `${y}-${pad(m)}`
	}
)

export const _selectLastMonthFormatted = createSelector(
	_selectFilterRaw,
	(raw) => {
		let y = raw.getIn(['lastMonth', 'year'])
		let m = raw.getIn(['lastMonth', 'month'])
		return `${y}-${pad(m)}`
	}
)

export const selectLimitedMonthsFormatted = createSelector(
	_selectFilterRaw,
	(raw) => {
		let ret = []
		let firstDate = new Date(raw.getIn(['firstMonth', 'year']), raw.getIn(['firstMonth', 'month']) - 1)
		let lastDate = new Date(raw.getIn(['lastMonth', 'year']), raw.getIn(['lastMonth', 'month']) - 1)
		let differMonths = ((lastDate.getFullYear() - firstDate.getFullYear()) * 12 + (lastDate.getMonth() - firstDate.getMonth()) + 1)
		let monthLimit = (differMonths > 6) ? 6 : differMonths
		for (let i = 0; i < monthLimit; i++) {
			let date = new Date(raw.getIn(['lastMonth', 'year']), raw.getIn(['lastMonth', 'month']) - 1)
			date.setMonth(date.getMonth() - i)
			ret.push(date.getFullYear() + '-' + pad(date.getMonth() + 1))
		}
		return ret
	}
)

export const selectTargetGrouping = createSelector(
	_selectFilterRaw,
	(dates) => {
		let y1 = dates.getIn(['firstMonth', 'year'])
		let m1 = dates.getIn(['firstMonth', 'month'])

		let y2 = dates.getIn(['lastMonth', 'year'])
		let m2 = dates.getIn(['lastMonth', 'month'])

		return ((y1 === y2) && (m1 === m2)) ? 'day' : 'month'
	}
)
