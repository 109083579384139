import React from 'react'


export const addKeys = (arr) => {
    let index = 0
    return arr.map((el) => React.createElement(el.type, {
        key: index++,
        ...el.props
    }))
}


export const traverseToTarget = (initialTarget, tagName) => {
	tagName = tagName.toUpperCase()

	// Traverse tree
	let target = initialTarget
	while (target.tagName !== tagName) {
		target = target.parentNode
		if (target.tagName === 'BODY') {
			return null // layout is fucked, fail silently
		}
	}
	return target
}
