import * as action from './action'
import { _selectFirstMonthFormatted, _selectLastMonthFormatted, selectFilterShort } from './selector/filter-local'
import { selectKnownShops } from './selector/common-local'


import { fromJS } from 'immutable'
// import { combineReducers } from 'redux-immutable'
import { handleActions } from 'redux-actions'
import moment from 'moment'


let now = moment()

const initialState = fromJS({
	filter: {
		// TODO: `day` is not actually used
		firstDay: { year: now.clone().subtract(5, 'month').year(), month: now.clone().subtract(5, 'month').month() + 1, day: 1 },
		lastDay: { year: now.year(), month: now.month() + 1, day: 1 },
		region: null,
		city: null,
		area: null,
		assembly_type: null,
		executor: null,
		client_type: null,
	},
	summary: {
		data: {/* managed by DataProvider */},
	},
	common: {
		data: {/* managed by DataProvider */},
	},
	generalFilterTuples: {
		data: {/* managed by DataProvider */},
	},
	general: {
		mode: 'satisfaction', // satisfaction, nps, count
		data: {/* managed by DataProvider */},
	},
	compare: {
		show: false,

		displayFilters: [],
		displayData: {data:[{}, {}]},

		nextFilters: [{
			columnKey: '',
			filter: {},
		}],
		nextData: {/* managed by DataProvider */ },
		criteria: 'nps', // satisfaction, nps
	},
	comment: {
		data: {/* managed by DataProvider */},
		detailed: {
			displayKey: null,
			nextKey: null,
			data: {/* managed by DataProvider */},
		},
		tag: {
			data: {/* managed by DataProvider */},
			key: null,
		},
	},
	rating: {
		criteria: 'satisfaction', // satisfaction, nps
		dataBy_area: {/* managed by DataProvider */},
	},
	// quarter: {
	// 	filterYear: 2018,
	// 	filterQuarter: 3,
	// 	data: {/* managed by DataProvider */},
	// },

})

const reducer = handleActions({
	[action.unsafeDirectSet](state, action) {
		return state.withMutations((state) => {
			let key = action.payload.key
			let value = action.payload.value

			if (typeof (value) === 'object') {
				console.log("Setting a non-primitive (potentially mutable) value in immutable tree. Looks like a mistake, ignoring.")
				return
			}

			state.setIn(key, value)
		})
	},

	[action.filterRegionChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'region'], value)
		})
	},

	[action.filterCityChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'city'], value)
		})
	},

	[action.filterAreaChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'area'], value)
		})
	},

	[action.filterAssemblyTypeChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'assembly_type'], value)
		})
	},

	[action.filterExecutorChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'executor'], value)
		})
	},

	[action.filterClientTypeChanged](state, action) {
		return state.withMutations((state) => {
			let { value } = action.payload
			state.setIn(['filter', 'client_type'], value)
		})
	},

	[action.filterDateFirstChanged](state, action) {
		return state.withMutations((state) => {
			let { year, month, day } = action.payload

			state.setIn(['filter', 'firstDay', 'year'], year)
			state.setIn(['filter', 'firstDay', 'month'], month)
			state.setIn(['filter', 'firstDay', 'day'], day)

			// if it's after last day, change last day as well
			let firstDay = moment(_selectFirstMonthFormatted(state), "YYYY-MM") // BUG
			let lastDay = moment(_selectLastMonthFormatted(state), "YYYY-MM")
			if (firstDay.isAfter(lastDay)) {
				state.setIn(['filter', 'lastDay', 'year'], year)
				state.setIn(['filter', 'lastDay', 'month'], month)
				state.setIn(['filter', 'lastDay', 'day'], day)
			}
		})
	},
	[action.filterDateLastChanged](state, action) {
		return state.withMutations((state) => {
			let { year, month, day } = action.payload

			state.setIn(['filter', 'lastDay', 'year'], year)
			state.setIn(['filter', 'lastDay', 'month'], month)
			state.setIn(['filter', 'lastDay', 'day'], day)

			// if it's before first day, change first day as well
			let firstDay = moment(_selectFirstMonthFormatted(state), "YYYY-MM")
			let lastDay = moment(_selectLastMonthFormatted(state), "YYYY-MM")
			if (lastDay.isBefore(firstDay)) {
				state.setIn(['filter', 'firstDay', 'year'], year)
				state.setIn(['filter', 'firstDay', 'month'], month)
				state.setIn(['filter', 'firstDay', 'day'], day)
			}
		})
	},
	[action.filterDateJump](state, action) {
		return state.withMutations((state) => {
			let { shortcut } = action.payload

			let t1 = now.clone()
			let t2 = now.clone()

			switch (shortcut) {
			case "reset":
				t1 = t1.subtract(5, 'month')
				break
			case "this-month":
				break
			case "prev-month":
				t1 = t1.subtract(1, 'month')
				t2 = t2.subtract(1, 'month')
				break
			case "this-quarter":
				t1 = t1.startOf('quarter')
				t2 = t1.clone().add(2, 'month')
				break
			case "prev-quarter":
				t1 = t1.subtract(3, 'month').startOf('quarter')
				t2 = t1.clone().add(2, 'month')
				break
			case "ytd":
				t1 = t1.startOf('year')
				break
			case "last-12-months":
				t1 = t1.subtract(11, 'month')
				break
			default:
				console.log(`Unknown shortcut: ${shortcut}`)
				return
			}

			state.setIn(['filter', 'firstDay', 'year'], t1.year())
			state.setIn(['filter', 'firstDay', 'month'], t1.month()+1)
			state.setIn(['filter', 'firstDay', 'day'], 1)

			state.setIn(['filter', 'lastDay', 'year'], t2.year())
			state.setIn(['filter', 'lastDay', 'month'], t2.month()+1)
			state.setIn(['filter', 'lastDay', 'day'], 1)
		})
	},

	[action.generalModeChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value

			state.setIn(['general', 'mode'], value)
		})
	},

	[action.commentKeyChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value

			let currentValue = state.getIn(['comment', 'detailed', 'nextKey'])
			state.setIn(['comment', 'detailed', 'nextKey'], (value === currentValue ? null : value))
		})
	},

	[action.commentTagsKeyChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value

			let currentValue = state.getIn(['comment','tag', 'key'])
			state.setIn(['comment','tag', 'key'], (value === currentValue ? null : value))
		})
	},

	[action.comparisonColumnAddCurrent](state, action) {
		return state.withMutations((state) => {
			let expectedFields = ['region', 'city', 'area', 'assembly_type', 'executor', 'client_type']
			let fs = selectFilterShort(state).unfreeze(expectedFields)

			let knownShops = selectKnownShops(state)

			let shop_fullname = ''
			for (let s of knownShops) {
				if (s.get('id') === fs.area) {
					shop_fullname = s.get('title')
					break
				}
			}

			let ck = []
			expectedFields.forEach((key) => {
				if (fs[key]) {
					let value = fs[key]
					if (key === 'area') value = shop_fullname
					ck.push(value)
				}
			})
			let columnKey = ck.join(' / ')

			if (columnKey === '') {
				columnKey = 'Вся сеть'
			}

			let alreadyExists = false
			for (let s of state.getIn(['compare', 'nextFilters'])) {
				if (s.get('columnKey') === columnKey) {
					alreadyExists = true
					break
				}
			}
			if (alreadyExists) {
				return
			}

			state.updateIn(['compare', 'nextFilters'], value => value.push(fromJS({
				columnKey: columnKey,
				filter: fs,
			})))
		})
	},

	[action.comparisonColumnDelete](state, action) {
		return state.withMutations((state) => {
			let { key } = action.payload

			state.updateIn(['compare', 'nextFilters'], arr => arr.filter((value) => value.get('columnKey') !== key))
		})
	},

	[action.comparisonDataLoaded](state, action) {
		return state.withMutations((state) => {
			let nextFilters = state.getIn(['compare', 'nextFilters'])
			state.setIn(['compare', 'displayFilters'], nextFilters)

			let nextData = state.getIn(['compare', 'nextData'])
			state.setIn(['compare', 'displayData'], nextData)
		})
	},

	[action.comparisonCriteriaChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value
			state.setIn(['compare', 'criteria'], value)
		})
	},

	[action.ratingCriteriaChanged](state, action) {
		return state.withMutations((state) => {
			let value = action.payload.value
			state.setIn(['rating', 'criteria'], value)
		})
	},
	[action.commentKeyLoaded](state, action) {
		return state.withMutations((state) => {
			let nextKey = state.getIn(['comment', 'detailed', 'nextKey'])
			state.setIn(['comment', 'detailed', 'displayKey'], nextKey)

		})
	},
}, initialState)

export default reducer
