import { ratingCriteriaChanged } from '../action'
import { selectRatingCriteria } from '../selector/rating-global'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'


class RatingCriteriaSwitch extends Component {
	render() {
		return (
			<div className="RatingCriteriaSwitch">
				<div className="btn-group">
					<button
						type="button"
						style={{width: '5em'}}
						className={"btn btn-info "+(this.props.value === 'nps' ? 'active' : '')}
						onClick={() => this.props.onChange('nps')}
					>&nbsp;&nbsp;NPS&nbsp;&nbsp;</button>
					<button
						type="button"
						style={{width: '9em'}}
						className={"btn btn-info "+(this.props.value === 'csi' ? 'active' : '')}
						onClick={() => this.props.onChange('csi')}
					>&nbsp;&nbsp;CSI (в целом)&nbsp;&nbsp;</button>
					<button
						type="button"
						style={{width: '11em'}}
						className={"btn btn-info "+(this.props.value === 'csi_worker' ? 'active' : '')}
						onClick={() => this.props.onChange('csi_worker')}
					>&nbsp;&nbsp;CSI (по мастерам)&nbsp;&nbsp;</button>
				</div>
			</div>
		)
	}
}

RatingCriteriaSwitch.propTypes = {
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func,
}


const mapStateToProps = (state, ownProps) => {
	return {
		value: selectRatingCriteria(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (value) => {
			dispatch(ratingCriteriaChanged(value))
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RatingCriteriaSwitch)
