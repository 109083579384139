import { filterRegionChanged, filterCityChanged, filterAreaChanged, filterExecutorChanged, filterWorkerChanged, filterTypeChanged } from '../action'
import PleaseSelectSearchable from '../../generic/PleaseSelectSearchable'
import { selectFilterShort } from '../selector/filter-global'
import { selectAllFilterTuples } from '../selector/generalfilters-global'

import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const isEmpty = (x) => (x === null || x === '')

const fdFilter = (data, region, city, area, executor, worker, type) => {
	return (
		data
		.filter(x => isEmpty(region) || x.region === region)
		.filter(x => isEmpty(city) || x.city === city)
		.filter(x => isEmpty(area) || x.area === area)
		.filter(x => isEmpty(executor) || x.executor === executor)
		.filter(x => isEmpty(worker) || x.worker === worker)
		.filter(x => isEmpty(type) || x.type === type)
	)
}

const fdPickUnique = (data, key) => {
	let titleField = (key !== 'area') ? key : 'fullname'

	let ids = []
	let matches = []
	for (let tuple of data) {
		if (!isEmpty(tuple[key])) {
			let pk = tuple[key]
			if (ids.indexOf(pk) < 0) {
				matches.push({
					id: pk,
					title: tuple[titleField],
				})
				ids.push(pk)
			}
		}
	}

	return matches.sort((a, b) => a.title.localeCompare(b.title))
}

class GeneralFilters extends Component {
	render() {
		let p = this.props
		let fullData = p.fullData.toJS()
		let fs = p.filterSet.unfreeze(['region', 'city', 'area', 'executor', 'worker', 'type'])

		let availableRegions = fdPickUnique(fdFilter(fullData, null, fs.city, fs.area, fs.executor, fs.worker, fs.type), 'region')
		let availableCities = fdPickUnique(fdFilter(fullData, fs.region, null, fs.area, fs.executor, fs.worker, fs.type), 'city')
		let avaliableShops = fdPickUnique(fdFilter(fullData, fs.region, fs.city, null, fs.executor, fs.worker, fs.type), 'area')
		let avaliableExecutors = fdPickUnique(fdFilter(fullData, fs.region, fs.city, fs.area, null, fs.worker, fs.type), 'executor')
		let avaliableWorkers = fdPickUnique(fdFilter(fullData, fs.region, fs.city, fs.area, fs.executor, null, fs.type), 'worker')
		let avaliableTypes = fdPickUnique(fdFilter(fullData, fs.region, fs.city, fs.area, fs.executor, fs.worker, null), 'type')

		return (
			<div className="GeneralFilters" style={{ 'display': 'inline-block' }}>
				<div style={{ 'display': 'inline-block', width: '13em', marginBottom: '0.3em' }}>
					<PleaseSelectSearchable
						data={availableRegions}
						nullOption="все регионы"
						value={fs.region}
						onChange={this.props.onRegionChange}
						style={{ maxWidth: '13em' }}
					/>
				</div>
				{' '}
				<div style={{ 'display': 'inline-block', width: '13em', marginBottom: '0.3em' }}>
					<PleaseSelectSearchable
						data={availableCities}
						nullOption="все города"
						value={fs.city}
						onChange={this.props.onCityChange}
						style={{ maxWidth: '13em' }}
					/>
				</div>
				{' '}
				<div style={{ 'display': 'inline-block', width: '13em', marginBottom: '0.3em'  }}>
					<PleaseSelectSearchable
						data={avaliableShops}
						nullOption="все магазины"
						value={fs.area}
						onChange={this.props.onAreaChange}
						style={{ maxWidth: '13em' }}
					/>
				</div>
				{' '}
				<div style={{ 'display': 'inline-block', width: '13em', marginBottom: '0.3em'  }}>
					<PleaseSelectSearchable
						data={avaliableExecutors}
						nullOption="все подрядчики"
						value={fs.executor}
						onChange={this.props.onExecutorChange}
						style={{ maxWidth: '13em' }}
					/>
				</div>
				{' '}
				<div style={{ 'display': 'inline-block', width: '13em', marginBottom: '0.3em'  }}>
					<PleaseSelectSearchable
						data={avaliableWorkers}
						nullOption="все мастера"
						value={fs.worker}
						onChange={this.props.onWorkerChange}
						style={{ maxWidth: '13em' }}
					/>
				</div>
				{' '}
				<div style={{ 'display': 'inline-block', width: '13em', marginBottom: '0.3em'  }}>
					<PleaseSelectSearchable
						data={avaliableTypes}
						nullOption="все категории услуг"
						value={fs.type}
						onChange={this.props.onTypeChange}
						style={{ maxWidth: '13em' }}
					/>
				</div>
			</div>
		)
	}
}

// GeneralFilters.propTypes = {
// }


const mapStateToProps = (state, ownProps) => {
	return {
		filterSet: selectFilterShort(state),
		fullData: selectAllFilterTuples(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onCityChange: (value) => {
			dispatch(filterCityChanged(value))
		},
		onRegionChange: (value) => {
			dispatch(filterRegionChanged(value))
		},
		onAreaChange: (value) => {
			dispatch(filterAreaChanged(value))
		},
		onWorkerChange: (value) => {
			dispatch(filterWorkerChanged(value))
		},
		onExecutorChange: (value) => {
			dispatch(filterExecutorChanged(value))
		},
		onTypeChange: (value) => {
			dispatch(filterTypeChanged(value))
		},
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralFilters)
