/*
Returns a function that accepts a selector and rebinds it the given path
in an immutable state tree.

Example usage:
	let unwrap = withPath(['delivery'])
	let globalSelector = unwrap(localSelector)
*/
export const withPath = (path) => (selector) => (state) => selector(state.getIn(path))


export const pad = (x) => {
	let ret = '' + x
	if (ret.length < 2) ret = '0' + ret
	return ret
}

export const formatMonth = (y, m) => y + '-' + pad(m)

export const formatDay = (y, m, d) => y + '-' + pad(m) + '-' + pad(d)

export const monthSequence = (firstMonth, firstYear, lastMonth, lastYear) => {
	// console.log(firstMonth, firstYear, lastMonth, lastYear)
	let ret = []

	let y1 = firstYear
	let m1 = firstMonth
	let y2 = lastYear
	let m2 = lastMonth

	ret.push(formatMonth(y1, m1))

	let count = 0

	while ((y1 < y2) || ((y1 === y2) && (m1 < m2))) {
		m1 += 1
		if (m1 > 12) {
			m1 = 1
			y1 += 1
		}

		// console.log(y1, m1)
		ret.push(formatMonth(y1, m1))

		count += 1; if (count > 1000) return ret // guard
	}

	return ret
}

export const roundPolicy = (x, dp = 1 ) => {
	if (x == null) return x
	return Math.round(x * (10 ** dp)) / (10 ** dp)
}
