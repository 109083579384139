import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { commentKeyChanged } from '../action'
import { selectCommentDetailedKeyRaw } from '../selector/comment-global'
import { selectFilterFull } from '../selector/filter-global'
import { selectMode } from '../selector/general-global'
import CommentDetailRoot from './CommentDetailRoot'
// import CommentDetail from './CommentDetail'
// import Dummy from '../../generic/Dummy'
import TableOverObject from '../../generic/TableOverObject'
import './Comments.css'

/*
		"count_comment_timing": 2277
        "count_comment_all_categories": 5879,
        "count_comment_price": 858,
        "count_comment_quality": 1824,
        "count_comment_shop": 1228,
        "count_comment_tidy": 1394,
        "count_comment_worker": 3712,
        "count_nps_detractor": 796,
        "count_nps_effective": 10108,
        "count_nps_promoter": 8537,
        "count_satisfaction_effective": 8803,
        "count_satisfaction_yes": 7934,
        "count_total": 10355,
        "when": "",
*/


const KEY_TOTAL = 'count_comment_all_categories'

const CRITERIA = [
	// title_original
	{ key: "count_comment_positive", title: 'Положительные комментарии (оценки 9-10)' },
	{ key: "count_comment_neutral", title: 'Нейтральные комментарии (оценки 7-8)' },
	{ key: "count_comment_negative", title: 'Негативные комментарии (оценки 1-6)' },
	{ key: "count_comment_notrated", title: 'Комментарии без оценки' },
]

class Comments extends Component {
	constructor() {
		super()
		this.handleRowClicked = this.handleRowClicked.bind(this)
	}

	handleRowClicked(key, event) {
		this.props.onDetailedKeyChange(key)
	}

	render() {
		let data = this.props.data.toJS()[0]

		let ret = { [KEY_TOTAL]: 0 }
		for (let crit of CRITERIA) ret[crit.key] = 0

		for (let monthData of data) {
			ret[KEY_TOTAL] += monthData[KEY_TOTAL]
			for (let crit of CRITERIA) {
				ret[crit.key] += monthData[crit.key]
			}
		}

		if (ret[KEY_TOTAL] === 0) return (
			<div className="Comments">
				<p>Нет данных за выбранный период.</p>
			</div>
		)

		let lowerPart = null
		if (this.props.detailedKey) lowerPart = (
			<CommentDetailRoot
				dataKey={this.props.detailedKey}
			/>
		)

		return (
			<div className="Comments">
				<div className="Comments">
					<TableOverObject
						definition={CRITERIA}
						data={ret}
						maxKey={KEY_TOTAL}
						tableClass= 'table table-condensed'
						rowClass={(def, data) => {
							return (def.key === this.props.detailedKey) ? 'bg-info' : ''
						}}
						onRowClicked={this.handleRowClicked}
					/>
					<div className="text-right">Всего людей, оставивших комментарии: {ret[KEY_TOTAL]}</div>
					{/* <div className="text-right text-muted"><small>Общая сумма может быть больше 100%, если комментарий затрагивает сразу несколько категорий.</small></div> */}
					<p>&nbsp;</p>
					{lowerPart}
				</div>
			</div>
		)
	}
}


const mapStateToProps = (state, ownProps) => {
	return {
		mode: selectMode(state),
		filterSet: selectFilterFull(state),
		detailedKey: selectCommentDetailedKeyRaw(state),
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onDetailedKeyChange: (k) => {
			dispatch(commentKeyChanged(k))
		}
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Comments)
